import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const LibraryCardSkeletons = () => (
  <LoadingContainer>
    {Array.from({ length: 12 }, (_, i) => (
      <LibraryCardContainer key={i}>
        <SkeletonComponent
          height="150px"
          width="100%"
          marginBottom="10px"
        />
        <WaveShape>
          <SkeletonComponent
            height="30px"
            width="30px"
            marginBottom="10px"
            borderRadius="100px"
          />
          <SkeletonComponent
            height="30px"
            width="90%"
            marginBottom="10px"
            borderRadius="10px"
          />
        </WaveShape>
        <ActionsButtonsContainer>
          <SkeletonComponent
            height="30px"
            width="48%"
            borderRadius="10px"
          />
          <SkeletonComponent
            height="30px"
            width="48%"
            borderRadius="10px"
          />
        </ActionsButtonsContainer>
      </LibraryCardContainer>
    ))}
  </LoadingContainer>
);

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonComponent = styled.div<{
  height?: any;
  width?: any;
  marginBottom?: any;
  borderRadius?: any;
}>`
  background: linear-gradient(to right, #0b0b0b 8%, #1b1b1b 18%, #0b0b0b 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 2s linear infinite;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const LibraryCardContainer = styled.div`
  background-color: #000;
  padding: 30px;
  border: 1px solid #333;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ActionsButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
`;

const WaveShape = styled.div`
  display: flex;
  flexdirection: row;
  gap: 5px;
`;

const LoadingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;
