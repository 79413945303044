import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import {
  createChatSessionApi,
  firstSegmentApi,
  getSessionApi,
  getSessionBySlugApi,
  getSessionsApi,
  nextSegmentApi,
  regenerateLastSegmentApi,
  regenerateSegmentApi,
} from '@api';
import { SegmentModel, SessionModel, UserModel } from '@models';
import { FormInputModel } from './form-input.store';
import { goto } from '@helpers';

export const sessionsModelStore: RecoilState<SessionModel[]> = atom<
  SessionModel[]
>({
  key: 'sessionsModelStore',
  default: undefined,
});

export const setSessionsModels = async (
  getAccessTokenSilently: CallableFunction,
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
  slugOrId?: string | number,
  disableRedict?: boolean,
): Promise<void> => {
  const sessions: SessionModel[] = await getSessionsApi(getAccessTokenSilently);
  setSessions(sessions);

  if (!disableRedict) {
    if (slugOrId === undefined) {
      const sessionsSortedByCreatedAt: SessionModel[] = [...sessions].sort(
        (a: SessionModel, b: SessionModel) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
      const lastSession: SessionModel | null = sessionsSortedByCreatedAt[0] || null;
      if (lastSession) {
        setSession(lastSession);
        window.history.pushState({}, '', `/session/${lastSession.slug}`);
      }
    } else {
      let forcedSession: SessionModel | null = null;
      if (typeof slugOrId === 'string' && Number.isNaN(parseInt(slugOrId))) {
        forcedSession = await getSessionBySlugApi(
          getAccessTokenSilently,
          slugOrId,
        );
        window.history.pushState({}, '', `/session/${forcedSession.slug}`);
      } else {
        forcedSession = await getSessionApi(
          getAccessTokenSilently,
          slugOrId as number,
        );
        window.history.pushState({}, '', `/session/${forcedSession.id}`);
      }
      setSession(forcedSession);
    }
  }
};

export const gotoSession = (
  setSession: SetterOrUpdater<SessionModel | null>,
  session: SessionModel | null,
): void => {
  if (!session) {
    return;
  }
  setSession(session);
  window.history.pushState({}, '', `/session/${session.id}`);
};

export const setSessionsModelsOnly = async (
  getAccessTokenSilently: CallableFunction,
  setSessions: SetterOrUpdater<SessionModel[]>,
): Promise<void> => {
  const sessions: SessionModel[] = await getSessionsApi(getAccessTokenSilently);
  setSessions(sessions);
};

export const setSessionModel = async (
  getAccessTokenSilently: CallableFunction,
  setSession: SetterOrUpdater<SessionModel | null>,
  sessionId: number,
): Promise<void> => {
  const session: SessionModel = await getSessionApi(
    getAccessTokenSilently,
    sessionId,
  );
  setSession(session);
};

export const createChatSession = async (
  getAccessTokenSilently: CallableFunction,
  sessions: SessionModel[],
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
  name: string,
): Promise<any> => {
  const sessionWithSameName: SessionModel | undefined = sessions?.find(
    (s: SessionModel) => s.name === name,
  );
  let name2: string = name;
  if (sessionWithSameName) {
    name2 = `${name} (${sessions.length})`;
  }
  const session: SessionModel = await createChatSessionApi(
    getAccessTokenSilently,
    name2,
  );
  setSessions([...sessions, session]);
  setSession(session);
  return session;
};

export const createFirstSegment = async (
  getAccessTokenSilently: CallableFunction,
  user: UserModel,
  formValues: FormInputModel,
  session: SessionModel | null,
  sessions: SessionModel[],
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
): Promise<void> => {
  if (session) {
    const updatedSession: SessionModel = await firstSegmentApi(
      getAccessTokenSilently,
      user,
      session.id,
      formValues.prompt,
      formValues.generationParams,
    );

    setSession(updatedSession);
    goto(`/session/${session.id}`);
    // await updateSession(updatedSession, sessions, setSessions, setSession);
  }
};

export const nextSegment = async (
  getAccessTokenSilently: CallableFunction,
  user: UserModel,
  formValues: FormInputModel,
  session: SessionModel | null,
  sessions: SessionModel[],
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
): Promise<void> => {
  if (session) {
    const updatedSession: SessionModel = await nextSegmentApi(
      getAccessTokenSilently,
      user,
      session.id,
      formValues.prompt,
      formValues.generationParams,
    );
    setSession(updatedSession);
    // await updateSession(updatedSession, sessions, setSessions, setSession);
  }
};

export const regenerateSegment = async (
  getAccessTokenSilently: CallableFunction,
  user: UserModel,
  formValues: FormInputModel,
  segment: SegmentModel,
  sessions: SessionModel[],
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
): Promise<void> => {
  const updatedSession: SessionModel = await regenerateSegmentApi(
    getAccessTokenSilently,
    user,
    segment.id,
    formValues.prompt,
    formValues.generationParams,
  );

  setSession(updatedSession);
  // await updateSession(updatedSession, sessions, setSessions, setSession);
};

export const regenarateLastSegment = async (
  getAccessTokenSilently: CallableFunction,
  user: UserModel,
  session: SessionModel | null,
  sessions: SessionModel[],
  setSessions: SetterOrUpdater<SessionModel[]>,
  setSession: SetterOrUpdater<SessionModel | null>,
): Promise<void> => {
  if (!session) {
    return;
  }
  const updatedSession: SessionModel = await regenerateLastSegmentApi(
    getAccessTokenSilently,
    user,
    session.id,
  );
  setSession(updatedSession);
  // await updateSession(updatedSession, sessions, setSessions, setSession);
};
