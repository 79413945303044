// src/stores/audioStore.ts
import { atom } from 'recoil';
import { SegmentModel } from '@models';

export class AudioState {
  audioPlayer: HTMLAudioElement = new Audio();

  progress: number = 0;

  currentTime: number = 0;

  duration: number = 0;
}
export const audioState = atom<AudioState>({
  key: 'audioState',
  default: new AudioState(),
});

export class CurrentPlayingTrackStateClass {
  segment: SegmentModel | null = null;
}

export const currentPlayingTrackState = atom<CurrentPlayingTrackStateClass>({
  key: 'currentPlayingTrackState',
  default: new CurrentPlayingTrackStateClass(),
});
