import {
  FormInputModel,
  createChatSession,
  createFirstSegment,
  nextSegment, updateCredits, userModelStore,
} from '@stores';
import { useState } from 'react';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { UserModel } from '@models';

export const usePromptSubmission = (
  getAccessTokenSilently,
  user,
  sendToaster,
  setToaster,
  setPromptFormModel,
  sessions,
  setSessions,
  setSession,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const setUser: SetterOrUpdater<UserModel | null> = useSetRecoilState(userModelStore);

  const handlePromptSubmit = async (prompt) => {
    if (prompt.trim().length === 0) {
      sendToaster('You cannot send an empty message', 'error', setToaster);
      return;
    }
    setIsLoading(true);
    try {
      await createChatSession(
        getAccessTokenSilently,
        sessions,
        setSessions,
        setSession,
        'New Session',
      )
        .then(async (session) => {
          const formValues: FormInputModel = {
            prompt,
            promptError: '',
            generationParams: {},
            bpm: 120,
            bpmError: '',
            key: 'C',
            keyError: '',
          };

          await createFirstSegment(
            getAccessTokenSilently,
            user,
            formValues,
            session,
            sessions,
            setSessions,
            setSession,
          );

          await updateCredits(getAccessTokenSilently, setUser);
        })
        .catch((e) => {
          sendToaster(
            e.message || 'Failed to create session',
            'error',
            setToaster,
          );
        });
      setPromptFormModel('');
    } catch (e: any) {
      sendToaster(e.message || 'An error occurred', 'error', setToaster);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromptWithAudioSubmit = async (prompt, session) => {
    if (prompt.trim().length === 0) {
      sendToaster('You cannot send an empty message', 'error', setToaster);
      return;
    }
    setIsLoading(true);
    try {
      const formValues: FormInputModel = {
        prompt,
        promptError: '',
        generationParams: {},
        bpm: 120,
        bpmError: '',
        key: 'C',
        keyError: '',
      };

      await createFirstSegment(
        getAccessTokenSilently,
        user,
        formValues,
        session,
        sessions,
        setSessions,
        setSession,
      );
      await updateCredits(getAccessTokenSilently, setUser);
    } catch (e: any) {
      sendToaster(e.message || 'An error occurred', 'error', setToaster);
    } finally {
      setIsLoading(false);
    }

    setPromptFormModel('');
  };

  const handleContinueSession = async (session) => {
    setIsLoading(true);
    try {
      await nextSegment(
        getAccessTokenSilently,
        user,
        {
          prompt: session.segments[0].prompt,
          promptError: '',
          generationParams: {},
          bpm: 120,
          bpmError: '',
          key: 'C',
          keyError: '',
        },
        session,
        sessions,
        setSessions,
        setSession,
      );
      await updateCredits(getAccessTokenSilently, setUser);
    } catch (e: any) {
      sendToaster(e.message || 'An error occurred', 'error', setToaster);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handlePromptSubmit,
    handlePromptWithAudioSubmit,
    handleContinueSession,
  };
};
