import { createTheme } from '@mui/material';

const fontFamily = 'Poppins, Neometric, Roboto, sans-serif';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    shadow: true;
    small: true;
    medium: true;
    large: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily,
    allVariants: {
      fontFamily,
      color: '#fff',
    },
    h3: {
      lineHeight: '8rem',
    },
    h4: {
      lineHeight: '5rem',
    },
  },
  palette: {
    primary: {
      main: '#013B8D',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          padding: '0.5rem',
          fontSize: '1.25rem',
          textTransform: 'none',
          fontFamily,
          backgroundColor: '#211F1F',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: '3rem',
          textTransform: 'none',
          color: '#fff',
          fontWeight: '600',
          '&.Mui-disabled': {
            opacity: 0.4,
            boxShadow: 'none',
            color: 'white',
          },
        },
      },
      variants: [
        {
          props: { variant: 'shadow' },
          style: {
            padding: '8px 16px',
            background: '#000000',
            boxShadow: '0 4px 80px 7px #B14348',
            borderRadius: '48px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '4px 10px',
            fontSize: '0.75rem',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '6px 20px',
            fontSize: '0.875rem',
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '10px 30px',
            fontSize: '1rem',
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
  },
});

export default theme;
