import * as React from 'react';
import CustomModal from '@mui/material/Modal';
import styled from '@emotion/styled';
import Close from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useResponsive } from '@hooks/useResponsive';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  headerIcon?: React.ReactNode;
  headerTitle?: string;
}

export default function Modal({
  children,
  open,
  onClose,
  headerIcon,
  headerTitle,
}: ModalProps) {
  const { isMobile } = useResponsive();

  return (
    <CustomModal style={customModalStyle} open={open} onClose={onClose}>
      <ModalContent isMobile={isMobile}>
        <HeaderContainer>
          <HeaderContent>
            {headerIcon}
            <Typography variant="h5">{headerTitle}</Typography>
          </HeaderContent>
          {onClose ? <CloseButton onClick={onClose} /> : null}
        </HeaderContainer>
        {children}
      </ModalContent>
    </CustomModal>
  );
}

const customModalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  overflow: 'auto',
};

const HeaderContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  gap: 10,
  padding: 10,
  paddingTop: 5,
  fontSize: 40,
  color: 'white',
  alignContent: 'flex-start',
});

const HeaderContainer = styled.div({
  display: 'flex',
  padding: 10,
  paddingBottom: 0,
});
const CloseButton = styled(Close)({
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  fontSize: 25,
  cursor: 'pointer',
  position: 'relative',
  right: 10,
  top: 10,
});

const ModalContent = styled.div<{ isMobile?: boolean }>(({ isMobile }) => ({
  backgroundColor: 'rgba(0,0,0,0.6)',
  minHeight: '30%',
  minWidth: '30%',
  borderRadius: 24,
  padding: isMobile ? 0 : 20,
  width: isMobile ? '100%' : 'auto',
  '&:focus': {
    outline: 'none',
  },
}));
