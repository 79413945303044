import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useResponsive } from '@hooks/useResponsive';
import {
  SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import {
  createChatSession, fileState, sendToaster, sessionModelStore, sessionsModelStore, toasterStore,
} from '@stores';
import { useNavigate } from 'react-router';
import { SessionModel } from '@models';
import { useAuth0 } from '@auth0/auth0-react';
import { uploadAudioApi } from '@api/upload-audio.api';
import { Loading } from '@components/loaders';

export const UploadSampleButton: React.FC = () => {
  const { isMobile } = useResponsive();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const setToaster = useSetRecoilState(toasterStore);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileInfo, setFileInfo] = useRecoilState(fileState);
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const sessions: SessionModel[] = useRecoilValue(sessionsModelStore);
  const setSessions: SetterOrUpdater<SessionModel[]> = useSetRecoilState(sessionsModelStore);
  const setSession: SetterOrUpdater<SessionModel | null> = useSetRecoilState(sessionModelStore);

  const { getInputProps } = useDropzone({
    accept: {
      'audio/mpeg': ['.mp3'],
      'audio/wav': ['.wav'],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    noClick: true,
    onDrop: async (acceptedFiles) => {
      setIsFileUploading(true);
      setFileInfo({
        file: acceptedFiles[0],
        fileName: acceptedFiles[0].name,
      });

      try {
        const session = await createChatSession(
          getAccessTokenSilently,
          sessions,
          setSessions,
          setSession,
          acceptedFiles?.[0]?.name,
        ).catch((e) => {
          sendToaster(
            e.message || 'Failed to create session',
            'error',
            setToaster,
          );
        });
        await uploadAudioApi(
          getAccessTokenSilently,
          session.id,
          acceptedFiles[0],
        ).catch((e) => {
          sendToaster(
            e.message || 'Failed to upload audio',
            'error',
            setToaster,
          );
        });
        setIsFileUploading(false);
        navigate(`/session/${session.id}`);
      } catch (e: any) {
        sendToaster(e.message || 'An error occurred', 'error', setToaster);
      }
    },
    onDropRejected: (fileRejections) => {
      const message = fileRejections?.some((rejection) =>
        rejection.errors.some((e) => e.code === 'file-too-large'))
        ? 'File is too large. Maximum size is 10MB.'
        : 'Invalid file type. Only MP3 and WAV files are accepted.';
      sendToaster(message, 'error', setToaster);
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = (): void => {
    inputRef.current?.click();
  };

  const inputProps = getInputProps();

  if (isFileUploading) {
    return (
      <UploadingContainer>
        <Loading message="Uploading the song" />
      </UploadingContainer>
    );
  }

  return (
    <UploadSampleButtonContainer onClick={onButtonClick} isMobile={isMobile}>
      or
      {' '}
      <UploadIcon />
      {' '}
      <Highlight>upload</Highlight>
      {' '}
      sample as inspiration
      <input {...inputProps} ref={inputRef} style={{ display: 'none' }} />
    </UploadSampleButtonContainer>
  );
};

const UploadingContainer = styled.div({
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
});

const UploadSampleButtonContainer = styled.button<{
  isMobile?: boolean;
}>(({ isMobile }) => ({
  color: 'white',
  fontSize: isMobile ? 30 : 45,
  opacity: 0.3,
  fontWeight: 'bold',
  textAlign: 'left',
  cursor: 'pointer',
}));

const Highlight = styled.span({
  textDecoration: 'underline',
  fontWeight: 'bold',
});

const UploadIcon = styled(FileUploadIcon)({
  fontSize: 45,
  fontWeight: 'bold',
});
