import { defaultSegmentDuration } from '@constants';
import { AudiocraftModels } from '@enums';

export class UserModel {
  id: number;

  email: string;

  active: boolean;

  audiocraftModelSettings: AudiocraftModels = AudiocraftModels.StereoLarge;

  defaultSegmentDuration: number = defaultSegmentDuration;

  permissions: string[] = [];

  credits: number;

  get accessPremiumActions(): boolean {
    return this.permissions.includes('access:premium-actions');
  }

  get accessSettings(): boolean {
    return this.permissions.includes('access:settings');
  }

  get isAdmin(): boolean {
    return this.permissions.includes('admin');
  }
}
