import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import {
  deleteSessionApi, getSegmentApi, getSessionApi, getSessionBySlugApi,
} from '@api';
import { SegmentModel, SessionModel } from '@models';

export const sessionModelStore: RecoilState<SessionModel | null> = atom<SessionModel | null>({
  key: 'sessionModelStore',
  default: null,
});

export const setSessionModelFomSessionId = async (getAccessTokenSilently: CallableFunction, setSession: SetterOrUpdater<SessionModel | null>, sessionId: number): Promise<void> => {
  const session: SessionModel = await getSessionApi(getAccessTokenSilently, sessionId);
  setSession(session);
};
export const setSessionModelFomSlug = async (getAccessTokenSilently: CallableFunction, setSession: SetterOrUpdater<SessionModel | null>, slug: string): Promise<void> => {
  const session: SessionModel = await getSessionBySlugApi(getAccessTokenSilently, slug);
  setSession(session);
};

export const updateSegmentFromApi = async (
  getAccessTokenSilently: CallableFunction,
  segment: SegmentModel,
): Promise<SegmentModel> => {
  const updatedSegment: SegmentModel = await getSegmentApi(getAccessTokenSilently, segment.id);
  return updatedSegment;
};

export const deleteSessionFromApi = async (
  getAccessTokenSilently: CallableFunction,
  session: SessionModel,
): Promise<SessionModel[]> => {
  const sessions: SessionModel[] = await deleteSessionApi(getAccessTokenSilently, session.id);
  return sessions;
};
