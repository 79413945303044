import './Loader.css';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { loadingStore } from '@stores';

export function Loader() {
  const loading: boolean = useRecoilValue(loadingStore);
  if (!loading) return <></>;
  return (
    <div className="loader">
      <CircularProgress />
    </div>
  );
}
