import { DemucsModel } from './demucs.model';
import { AudiocraftModels, SegmentsStatusEnum } from '@enums';

export class SegmentModel {
  id: number;

  sessionId: number;

  prompt: string;

  s3key: string;

  fileurl: string;

  created: Date;

  duration: number;

  generation_params: any;

  segmentNumber: number;

  progressFileurl: string;

  progressS3key: string;

  demucs: DemucsModel | null = null;

  wavformImage: string = '';

  isFirstSegment: boolean = false;

  isLastSegment: boolean = false;

  sessionName: string = '';

  audiocraftModel: AudiocraftModels = AudiocraftModels.StereoLarge;

  error: string | null = null;

  demucsError: string | null = null;

  segmentStatus: SegmentsStatusEnum = SegmentsStatusEnum.Pending;

  segmentDemucsStatus: SegmentsStatusEnum = SegmentsStatusEnum.Pending;
}
