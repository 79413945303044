import { axiosCall } from '@constants';
import { UserModel } from '@models';

export const me = async (
  getAccessTokenSilently: CallableFunction,
): Promise<UserModel> => axiosCall<UserModel>(
  getAccessTokenSilently,
  'me',
  'get',
  {},
  {},
);
