function sanitize(input, replacement) {
  // From https://github.com/parshap/node-sanitize-filename/blob/master/index.js

  const illegalRe = /[/?<>\\:*|"]/g;
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g;
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  const windowsTrailingRe = /[. ]+$/;

  if (typeof input !== 'string') {
    throw new Error('Input must be string');
  }
  const sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement);
  return sanitized.slice(0, 255);
}

export const downloadFile = async (fileUrl: string | undefined, filename: string = 'synthia') => {
  // replace all special characters
  filename = sanitize(filename, '');

  if (fileUrl === undefined) return;
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    const localUrl = window.URL.createObjectURL(blob);

    // Create a new anchor element
    const anchor = document.createElement('a');
    anchor.href = localUrl;
    anchor.download = `${filename}.mp3`; // You can specify a filename here

    // Append the anchor to the body, click it, and then remove it
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the Object URL and removing the anchor
    window.URL.revokeObjectURL(localUrl);
    document.body.removeChild(anchor);
  } catch (error: any) {
    console.error('Download failed:', error.message);
  }
};
