export class DigitalPipeConfiguration {
  domain = '';

  clientId = '';

  audience = '';

  scope = '';

  apiUrl = '';

  socketUrl = '';
}
