import React from 'react';
import styles from '../legal.module.scss';
import { Typography } from '@mui/material';
import { Scrollable } from '../../shared/Scrollable/Scrollable';

export const Terms = () => (
  <Scrollable className={styles.legal}>
    <Typography variant="h3">Legal informations</Typography>
    <Typography variant="subtitle2">Last Updated: 2024-03-19</Typography>

    <Typography variant="h4">1. Introduction</Typography>
    <Typography variant="body1">Welcome to Synthia, the Next-Gen AI Electronic Music Production Assistant, provided by MUSIC AI SAS ("we," "our," or "us"). These Terms and Conditions ("Terms") govern your use of Synthia, and by accessing or using Synthia, you agree to abide by these Terms. Please read them carefully.</Typography>

    <Typography variant="h4">2. User Agreement</Typography>
    <Typography variant="body1">
      By using Synthia, you agree to the following:

      <ul>
        <li>You are at least 18 years old or have reached the age of majority in your jurisdiction to enter into legally binding agreements.</li>
        <li>You will not use Synthia for any unlawful or unauthorized purpose.</li>
        <li>You will not attempt to circumvent any security measures or access features you are not authorized to use.</li>
        <li>You understand that the demo version of Synthia is limited in functionality and may not be used to commercialize tracks.</li>
      </ul>
    </Typography>

    <Typography variant="h4">3. Privacy Policy</Typography>
    <Typography variant="body1">Protecting your privacy is a top priority for us. Our Privacy Policy outlines how we collect, use, and protect your personal information in compliance with applicable regulations, including RGPD (Règlement Général sur la Protection des Données). By using Synthia, you consent to the practices described in our Privacy Policy.</Typography>

    <Typography variant="h4">4. Information Légales</Typography>
    <Typography variant="body1">
      MUSIC AI SAS is a société par actions simplifiée (SAS) incorporated in France. Our legal information is as follows:
      <ul>
        <li>Company Name: MUSIC AI SAS</li>
        <li>Registered Office: 5 RUE PLEYEL 93200 SAINT-DENIS</li>
        <li>Registration Number (SIRET): 98506006000011</li>
        <li>VAT Number: FR17985060060</li>
        <li>Legal Representative: Rose Capital SAS, président</li>
        <li>Contact Email: pierre@synthia.co</li>
      </ul>
    </Typography>

    <Typography variant="h4">5. Features and Functionality</Typography>
    <Typography variant="body1">
      Synthia offers various features, including but not limited to:
      <ul>
        <li>Text-to-Music Conversion: Transforming text inputs into sophisticated compositions.</li>
        <li>Sample Export: Exporting samples from Synthia-generated tracks for creative use.</li>
        <li>Stems Import: Integrating user's own musical elements into Synthia for a seamless blend of styles.</li>
        <li>Mixing & Mastering: Assisting in refining tracks for a polished sound output.</li>
      </ul>
    </Typography>

    <Typography variant="h4">6. Limitations of Use</Typography>
    <Typography variant="body1">While Synthia aims to provide a seamless music production experience, we acknowledge certain limitations, particularly in the demo version. Users are prohibited from using the demo version outputs for commercial purposes.</Typography>

    <Typography variant="h4">7. Data Collection and Use</Typography>
    <Typography variant="body1">
      We collect and use personal information in accordance with applicable laws and regulations, including RGPD. This includes but is not limited to:
      <ul>
        <li>Customer Data: Name, email address, and payment information for transaction facilitation.</li>
        <li>Contact Information: Confidentially stored and not shared with external entities.</li>
        <li>Cookies: Temporary cookies for user authentication and preferences.</li>
        <li>Analytical Data: Aggregate information for website analytics.</li>
        <li>Direct Marketing: Tailored advertisements based on user preferences.</li>
      </ul>
    </Typography>

    <Typography variant="h4">8. Data Sharing Policy</Typography>
    <Typography variant="body1">We do not share your personal data with third parties unless required by judicial orders or authorized governmental institutions.</Typography>

    <Typography variant="h4">9. Data Retention</Typography>
    <Typography variant="body1">We retain personal information as long as necessary for the purposes outlined in this policy. Users can request to amend or delete their personal information, except where retention is required for administrative, legal, or security reasons.</Typography>

    <Typography variant="h4">10. User Rights Over Data</Typography>
    <Typography variant="body1">Users have the right to request access to, correction of, or deletion of their personal data. Requests can be made through our designated contact channels.</Typography>

    <Typography variant="h4">11. Contact Us</Typography>
    <Typography variant="body1">
      For any inquiries or concerns regarding these Terms or our Privacy Policy, please contact us at:
      <ul>
        <li>MUSIC AI SAS</li>
        <li>5 RUE PLEYEL 93200 SAINT-DENIS</li>
        <li>pierre@synthia.co</li>
        <li>France Manager: Pierre de Conihout</li>
      </ul>
    </Typography>

    <Typography variant="h4">12. Updates to Terms</Typography>
    <Typography variant="body1">We reserve the right to update these Terms and our Privacy Policy at any time. Changes will be effective upon posting on our website. Continued use of Synthia constitutes acceptance of these changes.</Typography>

    <Typography variant="body1">Thank you for using Synthia. Let's create amazing music together!</Typography>

  </Scrollable>
);
