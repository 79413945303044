import { SessionModel } from '@models';
import { axiosCall } from '@constants';

export const getSessionApi = async (
  getAccessTokenSilently: CallableFunction,
  sessionId: number,
): Promise<SessionModel> => axiosCall<SessionModel>(
  getAccessTokenSilently,
  `session/${sessionId}`,
  'get',
);
