/* eslint-disable react/jsx-indent */
import styled from '@emotion/styled';
import {
  Button,
  PageContainer,
  UploadDropZoneWrapper,
  DisplayText,
  SoundPlayer,
  Prompt,
  Loading,
  GenerationLoader,
  SessionActions,
  DemucsModal,
} from '@components';
import { useResponsive } from '@hooks/useResponsive';
import {
  demucsSegmentFromApi,
  sendToaster,
  sessionModelStore,
  sessionsModelStore,
  setSessionModel,
  setSessionModelFomSessionId,
  toasterStore,
  userModelStore,
} from '@stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import userAvatar from '@assets/images/User_logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useImprovePrompt } from '@hooks/useImprovePrompt';
import {
  SegmentModel, SessionModel, SocketMessage, UserModel,
} from '@models';
import { SegmentsStatusEnum, SocketMessageType } from '@enums';
import { useMitt } from 'react-mitt';
import { usePromptSubmission } from '@hooks/usePromptSubmission';

interface PromptWithAudioPlayerProps {
  session: SessionModel | null;
}

const PromptWithAudioPlayer = ({ session }: PromptWithAudioPlayerProps) => (
  <SessionContainer>
    <DisplayText initialFontSize={20} text={session?.name} />
    <Margin />
    <SoundPlayer url={session?.uploadedAudioS3URL || ''} />
    <Margin />
  </SessionContainer>
);

export const SessionPage = () => {
  const { emitter } = useMitt();
  const { isMobile, isTablet } = useResponsive();
  const isSmallScreen = isMobile || isTablet;
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const [prompt, setPrompt] = useState('');
  const [results] = useState([{ promptValue: prompt }]);
  const [isGenerating, setIsGenerating] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const session = useRecoilValue(sessionModelStore);
  const user: UserModel | null = useRecoilValue(userModelStore);
  const sessions: SessionModel[] = useRecoilValue(sessionsModelStore);
  const setSessions: SetterOrUpdater<SessionModel[]> = useSetRecoilState(sessionsModelStore);
  const setSession: SetterOrUpdater<SessionModel | null> = useSetRecoilState(sessionModelStore);
  const setToaster = useSetRecoilState(toasterStore);
  const [open, setOpen] = useState(false);

  const { isImprovePromptLoading, handleImproveSubmit } = useImprovePrompt(
    getAccessTokenSilently,
    sendToaster,
    setPrompt,
    setToaster,
  );

  const { handleContinueSession, handlePromptWithAudioSubmit } = usePromptSubmission(
    getAccessTokenSilently,
    user,
    sendToaster,
    setToaster,
    setPrompt,
    sessions,
    setSessions,
    setSession,
  );

  const bottomRef: any = useRef(null);

  useEffect(() => {
    setSessionModel(getAccessTokenSilently, setSession, parseInt(slug));
  }, [slug, setSession, getAccessTokenSilently]);

  useEffect(() => {
    const terminateSegment = async (data: SocketMessage) => {
      if (data?.segmentId === session?.[0]?.segment?.id) {
        setSessionModelFomSessionId(
          getAccessTokenSilently,
          setSession,
          session?.[0].segment?.sessionId,
        );
      }
      window.location.replace(window.location.href);
    };

    if (session) {
      emitter.on(SocketMessageType.audiocraftTerminated, terminateSegment);
      if (session?.segments[0]?.prompt) setPrompt(session?.segments[0]?.prompt);
      if (
        session?.sessionStatus === SegmentsStatusEnum.Complete
        || session?.uploadedAudioS3URL
      ) {
        setIsGenerating(false);
        setButtonDisabled(false);
      } else setButtonDisabled(true);
      return () => {
        emitter.off(SocketMessageType.audiocraftTerminated, terminateSegment);
      };
    }
    return () => { };
  }, [session]);

  if (!slug) {
    window.location.href = '/';
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const addResultContainer = () => {
    handleContinueSession(session);
  };

  const startDemucs = async ({ session }: { session: SessionModel }) => {
    if (session?.segments.length === 0) return;
    const sessionSegments: SegmentModel[] | undefined = session?.segments;
    if (!sessionSegments) return;
    const lastSegment: SegmentModel = sessionSegments[sessionSegments.length - 1];
    await demucsSegmentFromApi(getAccessTokenSilently, lastSegment, setSession);
    // setOpen(true);
  };

  useEffect(() => {
    if (results.length > 1) {
      scrollToBottom();
    }
  }, [results]);

  if (!session) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <UploadDropZoneWrapper>
      <DemucsModal
        open={open}
        onClose={() => setOpen(false)}
        session={session}
      />
      <PageContainer newSongButton>
        <ContentContainer isSmallScreen={isSmallScreen}>
          {!isGenerating ? (
            <ShareContainer>
              <Button
                disabled
                type="outlined"
                size={isSmallScreen ? 'md' : 'sm'}
                text="Share"
                leftIcon={<ShareIcon />}
              />
            </ShareContainer>
          ) : null}
          {session?.uploadedAudioS3URL ? (
            <PromptWithAudioPlayer session={session} />
          ) : null}
          {session?.segments?.length > 0 ? (
            session?.segments
              ?.slice()
              .sort((a, b) => a.segmentNumber - b.segmentNumber)
              .map((result, index) => (
                <SessionContainer key={index}>
                  {index !== 0 ? <Divider /> : null}
                  <AddTimeContainer>
                    {index !== 0 ? (
                      <>
                        <AddTimeText>Continue (+30sec)</AddTimeText>
                        <ProfilePicture src={userAvatar} alt="User Avatar" />
                      </>
                    ) : null}
                  </AddTimeContainer>
                  {index === 0 ? (
                    <DisplayText initialFontSize={20} text={result.prompt} />
                  ) : null}
                  <Margin />
                  {result.segmentStatus !== SegmentsStatusEnum.Complete ? (
                    <GenerationLoader segment={session.segments[index]} />
                  ) : (
                    <SoundPlayer
                      url={result.fileurl}
                      title={
                        index === 0
                          ? 'Here is the result of your prompt :'
                          : 'The song with 30 secondes more'
                      }
                    />
                  )}
                  <Margin />
                  {session?.uploadedAudioS3URL
                    && index === 0
                    && result.segmentStatus !== SegmentsStatusEnum.Complete
                    && result.segmentNumber === session.segments.length - 1 ? (
                    <Prompt
                      value={prompt}
                      setValue={(value: string) => setPrompt(value)}
                      placeholder="Write your prompt"
                      minHeight="10vh"
                    />
                    ) : null}
                </SessionContainer>
              ))
          ) : (
            <Prompt
              value={prompt}
              setValue={(value: string) => setPrompt(value)}
              placeholder="Write your prompt"
              minHeight="10vh"
            />
          )}
          <ActionsContainer isSmallScreen={isSmallScreen}>
            <SessionActions
              startDemucs={() => {
                startDemucs({ session });
              }}
              isSmallScreen={isSmallScreen}
              prompt={prompt}
              setPrompt={setPrompt}
              buttonDisabled={buttonDisabled}
              addResultContainer={addResultContainer}
              handleImproveSubmit={handleImproveSubmit}
              handlePromptWithAudioSubmit={handlePromptWithAudioSubmit}
              isImprovePromptLoading={isImprovePromptLoading}
              session={session}
              setOpen={setOpen}
            />
            <div ref={bottomRef} />
          </ActionsContainer>
        </ContentContainer>
      </PageContainer>
    </UploadDropZoneWrapper>
  );
};

const LoadingContainer = styled.div({
  marginTop: -80,
  height: '100%',
});

const AddTimeText = styled.div({
  color: 'white',
  fontSize: 16,
  fontWeight: 900,
});

const ProfilePicture = styled.img({
  height: '2rem',
  width: '2rem',
});

const AddTimeContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 20,
  opacity: 1,
  zIndex: 100,
  alignItems: 'center',
});

const Divider = styled.div({
  width: '100%',
  height: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  marginTop: 20,
  marginBottom: 20,
});

const ShareContainer = styled.div({
  marginBottom: 20,
});

const SessionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
});

const Margin = styled.div({
  height: 20,
});

const ContentContainer = styled.div<{
  isSmallScreen?: boolean;
}>(({ isSmallScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: isSmallScreen ? '5vw' : '10%',
  paddingRight: isSmallScreen ? '5vw' : '10%',
  paddingBottom: 200,
  paddingTop: 20,
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignContent: 'center',
  minHeight: '100%',
}));

const ActionsContainer = styled.div<{ isSmallScreen }>({
  width: '100%',
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
});
