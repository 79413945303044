import styled from '@emotion/styled';
import Modal from './Modal';
import WaterIcon from '@mui/icons-material/Water';
import { Button, SoundPlayer } from '@components';
import { Typography } from '@mui/material';
import Toll from '@mui/icons-material/Toll';
import { useResponsive } from '@hooks/useResponsive';
import { DemucsModel, SegmentModel } from '@models';
import { downloadFile } from '@helpers';

interface DemucsModalProps {
    open: boolean;
    onClose: () => void;
    session: any;
}

interface DemucsItemProps {
    url: string;
    onDownload?: () => void;
    name?: string;
}

const DemucsItem = ({
  name,
  url,
  onDownload,
}: DemucsItemProps) => (
  <DemucsItemContainer>
    <TopRow>
      <Typography fontWeight={900} variant="h4">
        {name}
      </Typography>
      <Button size="md" rightIcon={<Toll />} text="Download 1" type="outlinedGradient" onClick={onDownload} />
    </TopRow>
    <SoundPlayer size="sm" url={url} />
  </DemucsItemContainer>
);

export const DemucsModal = ({
  open,
  onClose,
  session,
}: DemucsModalProps) => {
  const { isMobile } = useResponsive();

  const getLastSegmentFromSession = (): undefined | SegmentModel => {
    if (session?.segments.length === 0) return;
    const sessionSegments: SegmentModel[] | undefined = session?.segments;
    if (!sessionSegments) return;
    const lastSegment: SegmentModel = sessionSegments[sessionSegments.length - 1];
    // eslint-disable-next-line consistent-return
    return lastSegment;
  };

  const lastSegmentComp: undefined | SegmentModel = getLastSegmentFromSession();

  if (!lastSegmentComp) return null;

  const demucsData: DemucsModel | null = lastSegmentComp.demucs;

  if (!demucsData) return null;

  const demucsItems: {text: string, demucsAttributeName: string, value: string}[] = [];

  if (demucsData.drums) {
    demucsItems.push({ text: 'Drums', demucsAttributeName: 'drums', value: demucsData.drums });
  }

  if (demucsData.bass) {
    demucsItems.push({ text: 'Bass', demucsAttributeName: 'bass', value: demucsData.bass });
  }

  if (demucsData.other) {
    demucsItems.push({ text: 'Others', demucsAttributeName: 'other', value: demucsData.other });
  }

  if (demucsData.vocal) {
    demucsItems.push({ text: 'Vocals', demucsAttributeName: 'vocal', value: demucsData.vocal });
  }

  return (
    <Modal
      headerIcon={(
        <WaterIcon style={{
          fontSize: 30,
          marginRight: 5,
        }}
        />
            )}
      headerTitle="Extract samples"
      open={open}
      onClose={onClose}
    >
      <DemucsContainer isMobile={isMobile}>
        {
                    demucsItems?.map((item, index) => (
                      <DemucsItem
                        name={item.text}
                        key={index}
                        url={item.value}
                        onDownload={() => {
                          downloadFile(item.value, `${session.name}_${item.text}.wav`);
                        }}
                      />
                    ))
                }
      </DemucsContainer>
    </Modal>
  );
};

const TopRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: '100%',
});

const DemucsItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
});

const DemucsContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 20,
    paddingTop: 10,
    minWidth: isMobile ? '100%' : 500,
  }),
);
