import { SessionModel } from '@models';
import { axiosCall } from '@constants';

export const getSessionsApi = async (
  getAccessTokenSilently: CallableFunction,
): Promise<SessionModel[]> => axiosCall<SessionModel[]>(
  getAccessTokenSilently,
  'sessions',
  'get',
);
