import { SegmentModel, SessionModel } from '@models';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Button, GenerationLoader, SoundPlayer } from '@components';

import { ReactComponent as Duplicate } from '@assets/svgs/duplicate.svg';
import { useResponsive } from '@hooks/useResponsive';
import { SegmentsStatusEnum } from '@enums';
import { useNavigate } from 'react-router';

interface LibraryCardProps {
  session: SessionModel;
  masonry?: boolean;
  closeDrawer: () => void;
}

export const LibraryCard = ({ session, masonry = false, closeDrawer }: LibraryCardProps) => {
  const isFromSong = session?.uploadedAudioS3URL;
  const [firstSegment, setFirstSegment] = useState<SegmentModel | undefined>(
    undefined,
  );
  const navigate = useNavigate();

  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    setFirstSegment(
      [...session.segments].sort(
        (a, b) => a.segmentNumber - b.segmentNumber,
      )?.[0],
    );
  }, [session.segments]);

  if (session?.segments[0]?.segmentStatus !== SegmentsStatusEnum.Complete && !isFromSong) {
    return (
      <LibraryCardContainer
        onClick={() => {
          closeDrawer();
          navigate(`/session/${session.id}`);
        }}
        masonry={masonry}
      >
        <Typography style={styles.prompt}>
          {isFromSong ? session?.name : firstSegment?.prompt}
        </Typography>
        <GenerationContainer>
          <GenerationLoader
            small
            segment={
              isFromSong
                ? session.segments[0]
                : firstSegment || session.segments[0]
            }
          />
        </GenerationContainer>
      </LibraryCardContainer>
    );
  }

  if (!isFromSong && (!firstSegment || !firstSegment?.fileurl)) {
    return null;
  }
  if (isFromSong || firstSegment?.fileurl) {
    return (
      <LibraryCardContainer
        masonry={masonry}
        onClick={() => {
          closeDrawer();
          navigate(`/session/${session.id}`);
        }}
      >
        <TextContainer>
          <Typography style={styles.prompt}>
            {isFromSong ? session?.name : firstSegment?.prompt}
          </Typography>
        </TextContainer>

        <SoundWaveContainer>
          <SoundPlayer
            size="sm"
            url={
              isFromSong
                ? session.uploadedAudioS3URL
                : firstSegment?.fileurl || ''
            }
          />
        </SoundWaveContainer>
        <ActionsButtonsContainer>
          <Button
            disabled
            type="outlined"
            size={isMobile || isTablet ? 'md' : 'sm'}
            text="Share"
            leftIcon={<Share />}
          />
          <Button
            size="sm"
            type="outlined"
            text="Duplicate"
            disabled
            leftIcon={<Duplicate />}
          />
        </ActionsButtonsContainer>
      </LibraryCardContainer>
    );
  }

  return (
    <LibraryCardContainer
      masonry={masonry}
    >
      <EmptyContainer>
        <TextContainer>
          <Typography
            variant="h2"
            style={{
              marginBottom: 20,
              fontWeight: 700,
              ...styles?.prompt,
            }}
          >
            Empty song
          </Typography>
          <Typography variant="body2" style={styles.prompt}>
            Unleash your creativity: Add a prompt to compose your next
            masterpiece.
          </Typography>
        </TextContainer>
      </EmptyContainer>
    </LibraryCardContainer>
  );
};

const GenerationContainer = styled.div({
  marginTop: 10,
  marginBottom: -5,
});

const EmptyContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flex: 1,
});

const Share = styled(ShareIcon)({
  color: '#fff',
  fontSize: 24,
});

const styles = {
  prompt: {
    color: '#fff',
    fontSize: 16,
  },
};

const LibraryCardContainer = styled.div<{ masonry: boolean }>(
  {
    cursor: 'pointer',
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 30,
    borderRadius: '24px',
    border: '1px solid #333',
  },
);

const TextContainer = styled.div<{ masonry?: boolean }>(({ masonry }) => ({
  overflow: 'hidden',
  flex: masonry ? 'auto' : 1,
  marginBottom: 10,
}));

const SoundWaveContainer = styled.div({
  justifyContent: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
});

const ActionsButtonsContainer = styled.div({
  marginTop: 20,
  gap: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
