import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export class FormInputModel {
  prompt: string;

  promptError: string = '';

  generationParams: any;

  bpm: number;

  bpmError: string = '';

  key: string;

  keyError: string = '';
}

export const defaultFormInputModel: FormInputModel = {
  prompt: '',
  promptError: '',
  generationParams: {},
  bpm: 120,
  bpmError: '',
  key: 'C',
  keyError: '',
};

export const formInputModelStore: RecoilState<FormInputModel> = atom<FormInputModel>({
  key: 'formInputModelStore',
  default: defaultFormInputModel,
});

export const setPrompt = async (setFormInputModel: SetterOrUpdater<FormInputModel>, prompt: string): Promise<void> => {
  const formInputModel: FormInputModel = { ...defaultFormInputModel, prompt };
  setFormInputModel(formInputModel);
};
