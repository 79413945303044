import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { UserModel } from '@models';
import { me } from '@api';

export const userModelStore: RecoilState<UserModel | null> = atom<UserModel | null>({
  key: 'userModelStore',
  default: null,
});

export const setUserModelFromBackendWithAccessToken = async (getAccessTokenSilently: CallableFunction, setUser: SetterOrUpdater<UserModel>) => {
  const user: UserModel = await me(getAccessTokenSilently);
  setUser(user);
};
