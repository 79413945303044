import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { deleteSegmentApi, demucsSegmentApi, getSegmentApi } from '@api';
import { SegmentModel, SessionModel } from '@models';
import { setSessionModelFomSessionId } from './session-model.store';

export const segmentModelStore: RecoilState<SegmentModel | null> = atom<SegmentModel | null>({
  key: 'segmentModelStore',
  default: null,
});

export const setSegmentModelFomSegmentId = async (getAccessTokenSilently: CallableFunction, setSegment: SetterOrUpdater<SegmentModel>, segmentId: number): Promise<void> => {
  const segment: SegmentModel = await getSegmentApi(getAccessTokenSilently, segmentId);
  setSegment(segment);
};

export const deleteSegmentFromApi = async (
  getAccessTokenSilently: CallableFunction,
  segment: SegmentModel,
): Promise<SessionModel> => {
  const session: SessionModel = await deleteSegmentApi(getAccessTokenSilently, segment.id);
  return session;
};

export const demucsSegmentFromApi = async (
  getAccessTokenSilently: CallableFunction,
  segment: SegmentModel,
  setSession: SetterOrUpdater<SessionModel | null>,
): Promise<SegmentModel> => {
  const updatedSegment: SegmentModel = await demucsSegmentApi(getAccessTokenSilently, segment.id);
  await setSessionModelFomSessionId(getAccessTokenSilently, setSession, segment.sessionId);
  return updatedSegment;
};
