import { devConfiguration } from './dev';
import { prodConfiguration } from './prod';
import { DigitalPipeConfiguration } from './digital-pipe-configuration.class';
import { localConfiguration } from './local';

const env: string | undefined = process.env.REACT_APP_ENV;

const digitalPipeConfigurationMap = new Map<string, DigitalPipeConfiguration>();
digitalPipeConfigurationMap.set('dev', devConfiguration);
digitalPipeConfigurationMap.set('prod', prodConfiguration);
digitalPipeConfigurationMap.set('local', localConfiguration);
export const digitalPipeConfiguration: DigitalPipeConfiguration | undefined = digitalPipeConfigurationMap.get(env ?? 'dev');
