import styled from '@emotion/styled';

interface NoDataProps {
  text: string;
  CTA?: JSX.Element;
  icon: JSX.Element;
}

export const NoData = ({ icon, text, CTA }: NoDataProps) => (
  <Container>
    {icon}
    <Title>{text}</Title>
    {CTA}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5em;
`;
