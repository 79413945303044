import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import {
  Button, IconButton, LibraryCard, LibraryCardSkeletons, NoData,
} from '@components';
import { SessionModel } from '@models';
import Masonry from 'react-responsive-masonry';
import { useNavigate } from 'react-router';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';

interface LibraryListProps {
  sessions: SessionModel[];
  gap: number;
  masonry: boolean;
  columns?: number;
  loading?: boolean;
  closeDrawer: () => void;
}
export const LibraryList = ({
  sessions,
  gap,
  masonry,
  columns = 1,
  loading,
  closeDrawer,
}: LibraryListProps) => {
  const navigate = useNavigate();
  const nbSongs = sessions?.length || 0;

  if (loading) {
    return (
      <Container gap={gap} masonry={masonry}>
        <LibraryHeader>
          <Title>Loading songs..</Title>
        </LibraryHeader>
        <LibraryCardSkeletons />
      </Container>
    );
  }

  if (sessions?.length === 0) {
    return (
      <Container gap={gap} masonry={masonry}>
        <NoDataContainer>
          <NoData
            icon={<NoMusic />}
            text="No songs yet.."
            CTA={(
              <Button
                onClick={() => {
                  navigate('/');
                  closeDrawer();
                }}
                text="New song"
                type="outlinedGradient"
                rightIcon={<AddIcon />}
              />
            )}
          />
        </NoDataContainer>
      </Container>
    );
  }
  return (
    <Container gap={gap} masonry={masonry}>
      <LibraryHeader>
        <Title>{`Your library (${nbSongs})`}</Title>
        <DrawerButtons>
          {nbSongs > 0 ? (
            <Button
              onClick={() => navigate('/')}
              text="New song"
              type="outlinedGradient"
            />
          ) : null}
          <IconButton
            icon={<CloseIcon />}
            type="shadow"
            onClick={() => closeDrawer()}
          />
        </DrawerButtons>
      </LibraryHeader>
      {masonry ? (
        <Masonry columnsCount={columns} gutter={gap}>
          {sessions?.map((session, index) => (
            <LibraryCard masonry key={index} session={session} closeDrawer={closeDrawer} />
          ))}
        </Masonry>
      ) : (
        <ListContainer gap={gap} columns={columns}>
          {sessions?.map((session, index) => (
            <LibraryCard key={index} session={session} closeDrawer={closeDrawer} />
          ))}
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ gap: number; masonry: boolean }>({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 100,
});

const ListContainer = styled.div<{ gap: number; columns: number }>(
  ({ gap, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: `${gap}px`,
    width: '100%',
  }),
);

const LibraryHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
});

const Title = styled(Typography)({
  fontSize: '1.5rem',
  color: 'white',
  fontWeight: 'bold',
});

const NoMusic = styled(MusicOffIcon)({
  fontSize: '3rem',
  color: 'white',
});

const AddIcon = styled(Add)({
  fontSize: '1.5rem',
  color: 'white',
});

const NoDataContainer = styled.div({
  height: '70vh',
});

const DrawerButtons = styled.div({
  display: 'flex',
  gap: '1rem',
});

const CloseIcon = styled(Close)({
  fontSize: '1.2rem',
  color: 'white',
});
