import Modal from './Modal';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useResponsive } from '@hooks/useResponsive';

export const ReferralModal = () => {
  const { isMobile } = useResponsive();

  return (
    <Modal open>
      <ModalContent>
        <ModalTitle isMobile={isMobile}>
          You’re account is not validated yet …
          <br />
          {' '}
          <br />
          {' '}
          Come back regularly you might have a surprise. If you
          want to accelerate it, follow us on Instagram : @getsynthia
        </ModalTitle>
      </ModalContent>
    </Modal>
  );
};

interface ModalTitleProps {
  isMobile: boolean;
}

const ModalTitle = styled(Typography)<ModalTitleProps>(({ isMobile }) => ({
  fontSize: 20,
  fontWeight: 800,
  color: 'white',
  maxWidth: 500,
  fontFamily: 'Poppins',
  lineHeight: '35px',
  textAlign: 'center',
  marginTop: isMobile ? 20 : 0,
}));

const ModalContent = styled.div({
  flexDirection: 'column',
  border: 'none',
  alignItems: 'center',
  display: 'flex',
});
