import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export interface ToasterState {
  isOpen: boolean;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
}

export const defaultToasterState: ToasterState = {
  isOpen: false,
  message: '',
  severity: 'success',
};

export const toasterStore: RecoilState<ToasterState> = atom<ToasterState>({
  key: 'toasterStore',
  default: defaultToasterState,
});

export const sendToaster = (message: string, severity: 'error' | 'warning' | 'info' | 'success', setRecoil: SetterOrUpdater<ToasterState>) => {
  setRecoil({
    isOpen: true,
    message,
    severity,
  });
};
