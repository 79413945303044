import { axiosCall } from '@constants';
import { UploadAudioResponseDto } from '@dto/responses/upload-audio-response.dto';

export const uploadAudioApi = async (
  getAccessTokenSilently: CallableFunction,
  sessionId: string,
  file: File,
): Promise<UploadAudioResponseDto> => {
  const formData = new FormData();
  formData.append('sessionId', sessionId);
  formData.append('audioFile', file);

  console.log(file);

  return axiosCall<UploadAudioResponseDto>(
    getAccessTokenSilently,
    'upload-audio',
    'post',
    formData,
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};
