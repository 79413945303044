import { createBrowserRouter } from 'react-router-dom';
import { App, PromptPage, SessionPage } from '@pages';
import { Terms } from '@components/old/legal/Terms/Terms';
import { CheckoutForm } from './stripe';

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <PromptPage />,
      },
      {
        path: '/session/:slug',
        element: <SessionPage />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '/pay',
        element: <CheckoutForm />,
      },
    ],
  },
]);
