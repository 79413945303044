import { Alert, Snackbar } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ToasterState, toasterStore } from '@stores';
import styled from '@emotion/styled';

export const Toaster = () => {
  const toaster: ToasterState = useRecoilValue(toasterStore);
  const setToaster: CallableFunction = useSetRecoilState(toasterStore);

  const handleClose = () => {
    setToaster({ isOpen: false, message: '', severity: 'success' });
  };

  return (
    <SnackbarContainer
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={toaster.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={toaster.severity}
        sx={{ width: '100%' }}
      >
        {toaster.message}
      </Alert>
    </SnackbarContainer>
  );
};

const SnackbarContainer = styled(Snackbar)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
`;
