import React from 'react';
import styled from '@emotion/styled';
import { Ellipse } from './ShadowElipsis';

export const GradientElipsis = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <GradientElipsisContainer>
    <Ellipse />
    {children}
  </GradientElipsisContainer>
);

const GradientElipsisContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  margin: 0,
  padding: 0,
  paddingTop: 80,
});
