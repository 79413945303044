import React, { CSSProperties } from 'react';

export const Ellipse = ({ children }: any) => {
  const { width, height } = window.screen;
  const elipseWidth = width * 0.9;
  const elipseHeight = height * 0.8;
  const ellipseStyle: CSSProperties = {
    position: 'absolute',
    width: `${elipseWidth}px`,
    height: `${elipseHeight}px`,
    left: `calc(50% - ${elipseHeight}/2 - 0.5px)`,
    top: `calc(50% - ${elipseWidth}/2)`,
    background: 'linear-gradient(180deg, #FD7604 0%, #CC38AD 100%)',
    opacity: 0.9,
    filter: 'blur(150px)',
    borderRadius: '50%',
    zIndex: 9900,
  };

  return (
    <div
      style={
        {
          opacity: 0.6,
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          zIndex: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        } as CSSProperties
      }
    >
      <div style={ellipseStyle}>{children}</div>
    </div>
  );
};
