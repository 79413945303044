import WavesurferPlayer from '@wavesurfer/react';

type SoundWaveProps = {
  url: string;
  setWavesurfer: (ws: any) => void;
  size?: 'sm' | 'lg';
  onPlay?: any;
  onPause?: any;
};

export const SoundWave = ({
  url,
  setWavesurfer,
  size = 'lg',
  onPlay,
  onPause,
  ...props
}: SoundWaveProps) => {
  const onReady = (ws) => {
    setWavesurfer(ws);
  };

  return (
    <WavesurferPlayer
      width="100%"
      height={size === 'sm' ? 25 : 70}
      progressColor="#fff"
      barWidth={size === 'sm' ? 2 : 5}
      barRadius={400}
      barGap={size === 'sm' ? 2 : 7}
      waveColor="#CA3A64"
      url={url}
      onReady={onReady}
      cursorWidth={0}
      onPause={() => onPause()}
      onPlay={() => onPlay()}
      {...props}
    />
  );
};
