import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import styled from '@emotion/styled';

type IconButtonProps = {
  icon: React.ReactNode;
  onClick?: () => void;
  type?: 'shadow' | 'gradient' | 'outlined' | 'white';
  size?: number;
  disabled?: boolean;
};

export const IconButton = ({
  icon,
  onClick,
  type = 'shadow',
  size = 40,
  disabled,
}: IconButtonProps) => (
  <StyledIconButton
    disabled={disabled}
    type={type}
    size={size}
    onClick={onClick}
  >
    {icon}
  </StyledIconButton>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledIconButton = styled(({ type, size, ...otherProps }) => (
  <MuiIconButton {...otherProps} />
))(({ type, size }) => {
  const commonStyles = {
    width: `${size}px`,
    height: `${size}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    zIndex: 1,
    alignContent: 'center',
  };

  switch (type) {
    case 'shadow':
      return {
        ...commonStyles,
        background: '#000000',
        boxShadow: '0 4px 80px 7px #B14348',
      };
    case 'gradient':
      return {
        ...commonStyles,
        backgroundColor: 'transparent',
        background: 'linear-gradient(84.44deg, #f73f4d 5.33%, #e3278d 94.63%)',
      };
    case 'outlined':
      return {
        ...commonStyles,
        background: 'transparent',
        border: '2px solid #4B4B4B',
      };
    case 'white':
      return {
        ...commonStyles,
        background: '#FFFFFF',
      };
    default:
      return commonStyles;
  }
});
