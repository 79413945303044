import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { SwipeableDrawer } from '@mui/material';
import { useScroll } from '@contexts';

interface BottomDrawerProps {
  libraryOpen: boolean;
  setLibraryOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

export const BottomDrawer = ({
  libraryOpen,
  setLibraryOpen,
  children,
}: BottomDrawerProps) => {
  const { setScrollY } = useScroll();
  const drawerRef: any = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (drawerRef.current) {
        setScrollY(drawerRef.current.scrollTop);
      }
    };

    const drawerElement = drawerRef.current;
    if (drawerElement) {
      drawerElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (drawerElement) {
        drawerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setScrollY, children]);

  return (
    <BottomDrawerContainer
      PaperProps={paperStyle}
      anchor="bottom"
      // disableBackdropTransition={true}
      open={libraryOpen}
      onOpen={() => setLibraryOpen(true)}
      onClose={() => setLibraryOpen(false)}
    >
      <div ref={drawerRef} style={{ overflowY: 'auto', height: '100%' }}>
        {children}
      </div>
    </BottomDrawerContainer>
  );
};

const BottomDrawerContainer = styled(SwipeableDrawer)({
  backgroundColor: 'transparent',
  shadow: 'none',
});

const paperStyle = {
  style: {
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: 'none',
  },
};
