import { plainToInstance } from 'class-transformer';
import { SessionModel, UserModel } from '@models';
import { axiosCall } from '@constants';
import { GenerateMusicSegmentDto } from '@dto';

export const firstSegmentApi = async (
  getAccessTokenSilently: CallableFunction,
  user: UserModel,
  sessionId: number,
  prompt: string,
  generationParams: any,
): Promise<SessionModel> => axiosCall<SessionModel>(
  getAccessTokenSilently,
  'first-segment',
  'post',
  plainToInstance(GenerateMusicSegmentDto, {
    durationInSeconds: user.defaultSegmentDuration, sessionId, prompt, generationParams, audiocraftModel: user.audiocraftModelSettings,
  }),
);
