import { useState, useEffect } from 'react';

const useResponsive = () => {
  const breakpoints = {
    mobile: 480,
    tablet: 768,
    smallDesktop: 1024,
    mediumDesktop: 1200,
    desktop: 1440,
  };

  interface ScreenSizesProps {
    isMobile: boolean;
    isTablet: boolean;
    isSmallDesktop: boolean;
    isMediumDesktop: boolean;
    isDesktop: boolean;
  }

  const [screenSize, setScreenSize] = useState<ScreenSizesProps>({
    isMobile: false,
    isTablet: false,
    isSmallDesktop: false,
    isMediumDesktop: false,
    isDesktop: false,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < breakpoints.tablet,
        isTablet:
          width >= breakpoints.tablet && width < breakpoints.smallDesktop,
        isSmallDesktop:
          width >= breakpoints.smallDesktop && width < breakpoints.desktop,
        isMediumDesktop: width >= breakpoints.mediumDesktop,
        isDesktop: width >= breakpoints.desktop,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile: screenSize.isMobile,
    isTablet: screenSize.isTablet,
    isSmallDesktop: screenSize.isSmallDesktop,
    isMediumDesktop: screenSize.isMediumDesktop,
    isDesktop: screenSize.isDesktop,
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export { useResponsive };
