import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { StandardTextFieldProps } from '@mui/material';
import { useResponsive } from '@hooks/useResponsive';

interface FullWidthInputProps extends StandardTextFieldProps {
  fontSize: number;
  lineheight: string;
  minheight: string;
  desktop: string;
  mobile: string;
  tablet: string;
  inputvalue: string;
}

export const Prompt = ({
  value,
  setValue,
  initialFontSize = 117,
  placeholder = 'Write a prompt to generate music',
  disabled = false,
  minHeight = '20vh',
}: any) => {
  const [fontSize, setFontSize] = useState(initialFontSize);
  const { isMobile, isTablet, isDesktop } = useResponsive();

  useEffect(() => {
    let newFontSize = (initialFontSize / (isMobile ? 2.5 : isDesktop ? 1 : 1.7))
      * (1 / (1 + 0.01 * (value?.length || 0)));
    newFontSize = Math.max(newFontSize, 60);
    setFontSize(newFontSize);
  }, [value, initialFontSize, isMobile, isDesktop]);

  const handleChange = (e) => {
    const inputValue = e.target?.value;
    const capitalizedInput = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setValue(capitalizedInput);
  };

  return (
    <FullWidthInput
      disabled={disabled}
      desktop={isDesktop?.toString()}
      mobile={isMobile?.toString()}
      tablet={isTablet?.toString()}
      variant="standard"
      multiline
      placeholder={placeholder}
      autoFocus
      value={value}
      onChange={handleChange}
      fontSize={fontSize}
      lineheight={`${1.2 * fontSize}px`}
      minheight={minHeight}
      inputvalue={value}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

const FullWidthInput = styled(TextField) <FullWidthInputProps>`
  width: 100%;
  overflow-y: auto;
  min-height: ${(props) => props.minheight};
  max-height: 40vh;

  & .MuiInputBase-input {
    border: none;
    outline: none;
    color: white;
    font-size: ${(props) => props.fontSize}px;
    line-height: ${(props) => props.lineheight};
    font-weight: ${(props) => (props.inputvalue ? '100' : '600')};
    &:focus {
      border: none;
      outline: none;
    }
  }
`;
