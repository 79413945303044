import { plainToInstance } from 'class-transformer';
import { DigitalPipeConfiguration } from './digital-pipe-configuration.class';

export const devConfiguration: DigitalPipeConfiguration = plainToInstance(DigitalPipeConfiguration, {
  domain: 'audio-prompts.eu.auth0.com',
  clientId: 'lPHe39pgkgZsepnIxxbefDtKzmCi3zgs',
  audience: 'https://api.synthia-dev.com',
  scope: 'openid email profile',
  apiUrl: 'https://api.synthia-dev.com',
  socketUrl: 'https://socket.synthia-dev.com',
});
