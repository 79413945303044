import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
// import { RouterProvider } from 'react-router-dom';
// import { router } from '../router';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
// import { stripeProductId } from '@constants';

const stripePromise = loadStripe('pk_test_51OwpBKHYEQbxXQLJZmDrS8Hcm5ageGlX3FsfDkzdSjuxcxwCKhY3vkYzDm7YO0jIYaofo2Ek59JGQYZuYZ674S9R00CUQetaAw');

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  // const [clientSecret, setClientSecret] = useState('');

  /* useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch('/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: [{ id: stripeProductId }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []); */

  /* const appearance = {
    theme: 'stripe',
  }; */
  /* const options = {
    clientSecret,
    appearance,
  }; */

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}/success`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Elements
      stripe={stripePromise}
    >
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Submit</button>
      </form>
    </Elements>
  );
};
