import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useMitt } from 'react-mitt';
import { SocketMessageType } from '@enums';
import { SegmentModel, SocketMessage } from '@models';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { keyframes } from '@emotion/react';
import Synthia from '@assets/images/Synthia.png';

interface GenerationLoaderProps {
  segment: SegmentModel;
  small?: boolean;
}

export const GenerationLoader = ({ segment, small = false }: GenerationLoaderProps) => {
  const { emitter } = useMitt();
  const [currentProgress, setCurrentProgress] = useState(1);

  useEffect(() => {
    const handleProgressUpdate = (data: SocketMessage) => {
      if (data?.segmentId === segment?.id && data?.messageValue) {
        setCurrentProgress(data.messageValue);
      }
    };

    emitter.on(SocketMessageType.audiocraftProgress, handleProgressUpdate);

    return () => {
      emitter.off(SocketMessageType.audiocraftProgress, handleProgressUpdate);
    };
  }, [emitter, segment?.id]);

  return (
    <Container>
      {
        !small
          ? (
            <SynthiaIconContainer>
              <SynthiaIcon
                src={Synthia}
              />
            </SynthiaIconContainer>
          )
          : null
      }
      <LoadingBar>
        <ProgressBarBackground>
          <ProgressBar progress={currentProgress} />
        </ProgressBarBackground>
        {
          !small
            ? (
              <GenerationTextContainer>
                <TipsAndUpdatesIcon />
                <GenerationText>
                  Generation may take up to 5 minutes.
                  Note this is an alpha and can produce weird results.
                  If you're not satisfied, we recommend you to start a new track.
                </GenerationText>
              </GenerationTextContainer>
            )
            : (
              <GenerationTextContainer small>
                {`${currentProgress}%`}
              </GenerationTextContainer>
            )
        }

      </LoadingBar>

    </Container>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const GenerationTextContainer = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  justify-content: ${(props) => (props.small ? 'center' : 'flex-start')};
`;

const LoadingBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const GenerationText = styled.span`
  font-size: 12px;
  font-weight: 800;
`;

const SynthiaIconContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  `;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => (props.progress > 100 ? 100 : props.progress)}%;
  height: 10px;
  background: linear-gradient(90deg, #ca3a64 0%, #e98e2c 100%);
  transition: width 0.5s ease;
`;

const ProgressBarBackground = styled.div`
  width: 100%;
  height: 10px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
`;

const SynthiaIcon = styled.img({
  objectFit: 'contain',
  padding: 5,
  animation: `${spin} 4s linear infinite`,
});
