import { PageContainer } from '@components/containers';
import styled from '@emotion/styled';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  sendToaster,
  toasterStore,
  fileState,
  createChatSession,
  sessionsModelStore,
  sessionModelStore,
} from '@stores';
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useNavigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { SessionModel } from '@models';
import { uploadAudioApi } from '@api/upload-audio.api';
import { Loading } from '@components/loaders';

interface UploadDropZoneWrapperProps {
  children: React.ReactNode;
}

export const UploadDropZoneWrapper = ({ children }: UploadDropZoneWrapperProps) => {
  const [isFileOver, setIsFileOver] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const setToaster = useSetRecoilState(toasterStore);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileInfo, setFileInfo] = useRecoilState(fileState);
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const sessions: SessionModel[] = useRecoilValue(sessionsModelStore);
  const setSessions: SetterOrUpdater<SessionModel[]> = useSetRecoilState(sessionsModelStore);
  const setSession: SetterOrUpdater<SessionModel | null> = useSetRecoilState(sessionModelStore);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mpeg': ['.mp3'],
      'audio/wav': ['.wav'],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    noClick: true,
    onDrop: async (acceptedFiles) => {
      setIsFileUploading(true);
      setIsFileOver(false);
      setFileInfo({
        file: acceptedFiles[0],
        fileName: acceptedFiles[0].name,
      });

      try {
        const session = await createChatSession(
          getAccessTokenSilently,
          sessions,
          setSessions,
          setSession,
          acceptedFiles?.[0]?.name,
        ).catch((e) => {
          sendToaster(
            e.message || 'Failed to create session',
            'error',
            setToaster,
          );
        });
        await uploadAudioApi(
          getAccessTokenSilently,
          session?.id,
          acceptedFiles[0],
        ).catch((e) => {
          sendToaster(
            e.message || 'Failed to upload audio',
            'error',
            setToaster,
          );
        });
        setIsFileUploading(false);
        navigate(`/session/${session?.id}`);
      } catch (e: any) {
        sendToaster(e.message || 'An error occurred', 'error', setToaster);
      }
    },
    onDropRejected: (fileRejections) => {
      setIsFileOver(false);
      const message = fileRejections?.some((rejection) =>
        rejection.errors.some((e) => e.code === 'file-too-large'))
        ? 'File is too large. Maximum size is 10MB.'
        : 'Invalid file type. Only MP3 and WAV files are accepted.';
      sendToaster(message, 'error', setToaster);
    },
    onDragEnter: () => setIsFileOver(true),
    onDragLeave: () => setIsFileOver(false),
  });

  if (isFileUploading) {
    return (
      <PageContainer>
        <LoadingContainer>
          <UploadContainer>
            <Loading message="Uploading the song" />
          </UploadContainer>
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <div {...getRootProps()} style={{ width: '100%', height: '100%' }}>
      <input {...getInputProps()} />
      {isFileOver ? (
        <PageContainer>
          <UploadContainer>
            <UploadIcon />
            <UploadText>Drop to upload</UploadText>
          </UploadContainer>
        </PageContainer>
      ) : (
        children
      )}
    </div>
  );
};

const LoadingContainer = styled.div`
 margin-top: -5%;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const UploadIcon = styled(FileUploadIcon)({
  fontSize: 100,
  fontWeight: 'bold',
  color: 'white',
});

const UploadText = styled(Typography)`
  font-size: 40px;
  font-weight: 400;
  color: white;
`;
