import { atom, RecoilState } from 'recoil';

export class SessionProgress {
  sessionId: number;

  progress: number;
}
export const sessionProgressStore: RecoilState<SessionProgress[]> = atom<SessionProgress[]>({
  key: 'sessionProgressStore',
  default: [],
});
