import axios, { AxiosInstance } from 'axios';
import { digitalPipeConfiguration } from '@config';

interface HttpHeaders {
  [header: string]: string;
}
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: digitalPipeConfiguration?.apiUrl,
});

export const axiosCall = <T>(
  getAccessTokenSilently: CallableFunction,
  path: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete' | 'option' = 'get',
  payload?: unknown,
  additionalHeaders?: HttpHeaders,
): Promise<T> =>
  // console.log('axiosCall', apiUrl + '/' + path);
    new Promise((resolve, reject) => {
      getAccessTokenSilently().then(
        (token: string) => {
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
          axios({
            url: `${digitalPipeConfiguration?.apiUrl}/${path}`,
            method,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              ...additionalHeaders,
            },
            data: payload === undefined ? undefined : payload,
          })
            .then((response: { data: T }) => {
              resolve(response.data as T);
            })
            .catch((error: Error) => {
              reject(error);
            });
        },
        (err) => {
          reject(err);
        },
      );
    });
export const axiosCallPublic = <T>(
  path: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete' | 'option' = 'get',
  payload?: unknown,
  additionalHeaders?: HttpHeaders,
): Promise<T> => {
  console.log('axiosCall', `${digitalPipeConfiguration?.apiUrl}/${path}`);
  return new Promise((resolve, reject) => {
    axios({
      url: `${digitalPipeConfiguration?.apiUrl}/${path}`,
      method,
      headers: {
        'Content-Type': 'application/json',
        ...additionalHeaders,
      },
      data: payload === undefined ? undefined : payload,
    })
      .then((response: { data: T }) => {
        resolve(response.data as T);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
