import { plainToInstance } from 'class-transformer';
import { SessionModel } from '@models';
import { axiosCall } from '@constants';
import { CreateSessionDto } from '@dto';

export const createChatSessionApi = async (
  getAccessTokenSilently: CallableFunction,
  name: string,
): Promise<SessionModel> => axiosCall<SessionModel>(
  getAccessTokenSilently,
  'session',
  'post',
  plainToInstance(CreateSessionDto, { name }),
);
