import { SegmentModel } from './segment.model';
import { SegmentsStatusEnum } from '@enums';

export class SessionModel {
  id: number;

  slug: string;

  userId: number;

  name: string;

  segments: SegmentModel[];

  start_time: Date;

  end_time: Date;

  status: string;

  uploadedAudioS3URL: string;

  s3key: string;

  fileurl: string;

  createdAt: Date;

  updatedAt: Date;

  public?: boolean;

  wavformImage: string = '';

  sessionStatus: SegmentsStatusEnum = SegmentsStatusEnum.Pending;

  get sessionHasAnError(): boolean {
    return this.segments.reduce((hasError: boolean, segment: SegmentModel) => {
      if (!hasError && segment.error && segment.error.length > 0) {
        hasError = true;
      }
      return hasError;
    }, false);
  }

  get isGenerating(): boolean {
    return this.segments.reduce(
      (isGenerating: boolean, segment: SegmentModel) => {
        if (!isGenerating && segment.progressS3key.trim().length === 0) {
          isGenerating = true;
        }
        return isGenerating;
      },
      false,
    );
  }

  get totalDurationInseconds(): number {
    return this.segments.reduce(
      (totalDuration: number, segment: SegmentModel) =>
        totalDuration + segment.duration,
      0,
    );
  }

  get seconds(): number {
    return this.totalDurationInseconds;
  }

  get prettyDuration(): string {
    const totalSeconds: number = this.totalDurationInseconds;
    const hours: number = Math.floor(totalSeconds / 3600);
    const minutes: number = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds: number = totalSeconds - hours * 3600 - minutes * 60;

    if (seconds < 60 && minutes < 1) {
      return `${seconds}s`;
    }
    if (seconds > 0) {
      return `${minutes}min${seconds}`;
    }
    return `${minutes}min`;
  }
}
