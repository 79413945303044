import { defaultSegmentDuration } from '@constants';
import { AudiocraftModels } from '@enums';

export class ContinueMusicSegmentDto {
  sessionId: number;

  prompt: string;

  generationParams: any;

  durationInSeconds: number = defaultSegmentDuration;

  audiocraftModel: AudiocraftModels = AudiocraftModels.StereoLarge;
}
