import { atom, RecoilState, SetterOrUpdater } from 'recoil';
import { getSegmentApi } from '@api';
import { SegmentModel } from '@models';

export const segmentsModelStore: RecoilState<SegmentModel[]> = atom<SegmentModel[]>({
  key: 'segmentsModelStore',
  default: [],
});

export const setSegmentsModelsFomSessionId = async (getAccessTokenSilently: CallableFunction, setSegment: SetterOrUpdater<SegmentModel>, segmentId: number): Promise<void> => {
  const segment: SegmentModel = await getSegmentApi(getAccessTokenSilently, segmentId);
  setSegment(segment);
};
