import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { plainToInstance } from 'class-transformer';
import userAvatar from '@assets/images/User_logo.png';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { UserModel } from '@models';
import { userModelStore } from '@stores';
import { me } from '@api';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

export const ProfileMenu = () => {
  const {
    getAccessTokenSilently, isAuthenticated, user, logout,
  } = useAuth0();

  let synthiaUser: UserModel | null = useRecoilValue(userModelStore);
  const setUser: SetterOrUpdater<UserModel | null> = useSetRecoilState(userModelStore);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      me(getAccessTokenSilently)
        .then((oktaUser: UserModel) => {
          setUser(
            plainToInstance(UserModel, {
              id: oktaUser.id,
              email: oktaUser.email,
              active: oktaUser.active,
              audiocraftModelSettings: oktaUser.audiocraftModelSettings,
              defaultSegmentDuration: oktaUser.defaultSegmentDuration,
              permissions: oktaUser.permissions,
              credits: oktaUser.credits,
            }),
          );
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [isAuthenticated, getAccessTokenSilently, setUser, user]);

  synthiaUser = plainToInstance(UserModel, synthiaUser);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: { currentTarget }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) return <></>;
  if (!synthiaUser) return <></>;

  return (
    <>
      <ProfileButton>
        <span onClick={handleClick}>{user.name}</span>
        <ProfilePicture
          onClick={handleClick}
          src={userAvatar}
          alt="User Avatar"
        />
      </ProfileButton>
      {open ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {synthiaUser.isAdmin ? (
            <MenuItem
              onClick={() => {
                navigate('/settings');
              }}
            >
              Settings
            </MenuItem>
          ) : (
            <></>
          )}
          {synthiaUser.isAdmin ? (
            <MenuItem
              onClick={() => {
                navigate('/admin-users');
              }}
            >
              Admin users
            </MenuItem>
          ) : (
            <></>
          )}
          <MenuItem
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
};

const ProfileButton = styled(Button)({
  display: 'flex',
  gap: '1rem',
});

const ProfilePicture = styled.img({
  height: '2rem',
  width: '2rem',
});
