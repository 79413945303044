import { atom, RecoilState } from 'recoil';
import { plainToInstance } from 'class-transformer';
import { SessionModel } from '@models';

export class SidebarState {
  clickedSession: SessionModel | null;

  open: boolean;

  anchorEl: null | any;
}

export const defaultSidebarState: SidebarState = plainToInstance(SidebarState, {
  clickedSession: null,
  open: false,
});

export const sidebarStore: RecoilState<SidebarState> = atom<SidebarState>({
  key: 'sidebarStore',
  default: defaultSidebarState,
});
