import React from 'react';
import styles from './Scrollable.module.scss';

interface LibraryGalleryProps {
    children?: React.ReactNode
    className?: string
}

export const Scrollable = ({ children, className }: LibraryGalleryProps) => (
  <div className={`${className} ${styles.scrollable}`}>
    {children}
  </div>
);
