import React from 'react';
import styled from '@emotion/styled';
import { useResponsive } from '@hooks/useResponsive';

interface DisplayTextProps {
  fontSize: number;
  lineHeight: string;
  desktop: string;
  mobile: string;
  tablet: string;
  text: string;
}

export const DisplayText = ({ text, initialFontSize = 117 }: any) => {
  const [fontSize, setFontSize] = React.useState(initialFontSize);
  const { isMobile, isTablet, isDesktop } = useResponsive();

  React.useEffect(() => {
    let newFontSize = (initialFontSize / (isMobile ? 2.5 : isDesktop ? 1 : 1.7))
      * (1 / (1 + 0.01 * (text?.length || 0)));
    newFontSize = Math.max(newFontSize, 35);
    setFontSize(newFontSize);
  }, [text, initialFontSize, isMobile, isDesktop]);

  return (
    <StyledDisplayText
      desktop={isDesktop.toString()}
      mobile={isMobile.toString()}
      tablet={isTablet.toString()}
      fontSize={fontSize}
      lineHeight={`${1.4 * fontSize}px`}
      text={text}
    >
      {text}
    </StyledDisplayText>
  );
};

const StyledDisplayText = styled.div<DisplayTextProps>`
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
  color: white;
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight};
  font-weight: 300;
  z-index: 1;
`;
