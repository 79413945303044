import { Button } from '@components/buttons';
import Modal from './Modal';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useResponsive } from '@hooks/useResponsive';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthModal = () => {
  const { isMobile } = useResponsive();
  const { loginWithRedirect } = useAuth0();

  return (
    <Modal open>
      <ModalContent>
        <ModalTitle isMobile={isMobile}>
          Start using our platform to create your own music
        </ModalTitle>
      </ModalContent>
      <ButtonsContainer>
        <Button
          size="lg"
          type="gradient"
          text="Sign Up"
          onClick={() => loginWithRedirect()}
        />
        <Button
          type="outlinedGradient"
          text="Create an account"
          onClick={() => loginWithRedirect()}
        />
      </ButtonsContainer>
    </Modal>
  );
};

interface ModalTitleProps {
  isMobile: boolean;
}

const ModalTitle = styled(Typography)<ModalTitleProps>(({ isMobile }) => ({
  fontSize: 32,
  fontWeight: 800,
  color: 'white',
  maxWidth: 400,
  fontFamily: 'Poppins',
  lineHeight: '40px',
  textAlign: 'center',
  marginTop: isMobile ? 20 : 0,
}));

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 16,
  padding: 30,
  marginTop: 10,
  justifyContent: 'center',
  height: '100%',
  border: 'none',
});

const ModalContent = styled.div({
  flexDirection: 'column',
  border: 'none',
  alignItems: 'center',
  display: 'flex',
  gap: 24,
  marginTop: -20,
  marginRight: 10,
});
