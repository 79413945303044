import { plainToInstance } from 'class-transformer';
import { axiosCall } from '@constants';
import { ImprovePromptResponseDto } from '@dto/responses/improve-prompt-response.dto';
import { ImprovePromptRequestDto } from '@dto/requests/improve-prompt-request.dto';

export const improvePromptApi = async (
  getAccessTokenSilently: CallableFunction,
  text: string,
): Promise<ImprovePromptResponseDto> => axiosCall<ImprovePromptResponseDto>(
  getAccessTokenSilently,
  'improve-prompt',
  'post',
  plainToInstance(ImprovePromptRequestDto, { text }),
);
