import styled from '@emotion/styled';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Toll from '@mui/icons-material/Toll';
import TuneIcon from '@mui/icons-material/Tune';
import WavesIcon from '@mui/icons-material/Waves';
import { ReactComponent as Duplicate } from '@assets/svgs/duplicate.svg';
import {
  Button,
  Loader,
} from '@components';
import { SegmentModel, SessionModel, SocketMessage } from '@models';
import { SegmentsStatusEnum, SocketMessageType } from '@enums';
import { sendToaster, toasterStore } from '@stores';
import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { useMitt } from 'react-mitt';

interface SessionActionsProps {
  isSmallScreen: boolean;
  prompt: string;
  setPrompt: (value: string) => void;
  buttonDisabled: boolean;
  addResultContainer: () => void;
  handleImproveSubmit: (prompt: string) => void;
  isImprovePromptLoading: boolean;
  handlePromptWithAudioSubmit: (prompt: string,
    session: SessionModel
  ) => void;
  session: SessionModel;
  startDemucs: () => void;
  setOpen: (value: boolean) => void;
}

export const SessionActions = ({
  isSmallScreen,
  prompt,
  buttonDisabled,
  addResultContainer,
  handleImproveSubmit,
  handlePromptWithAudioSubmit,
  isImprovePromptLoading,
  session,
  startDemucs,
  setOpen,
}: SessionActionsProps) => {
  const setToaster = useSetRecoilState(toasterStore);
  const canContinue = !session?.uploadedAudioS3URL || (session?.segments?.length > 0
    && session?.uploadedAudioS3URL);

  const getLastSegmentFromSession = (): undefined | SegmentModel => {
    if (session?.segments.length === 0) return;
    const sessionSegments: SegmentModel[] | undefined = session?.segments;
    if (!sessionSegments) return;
    const lastSegment: SegmentModel = sessionSegments[sessionSegments.length - 1];
    // eslint-disable-next-line consistent-return
    return lastSegment;
  };

  const lastSegmentComp: undefined | SegmentModel = getLastSegmentFromSession();

  const demucsInProgressFromBackend: boolean = lastSegmentComp !== undefined && lastSegmentComp.segmentDemucsStatus
    === SegmentsStatusEnum.Processing;

  const [demucsInProgress, setDemucsInProgress] = useState<boolean>(demucsInProgressFromBackend);

  const { emitter } = useMitt();

  useEffect(() => {
    const demucsStartedListener = (data: SocketMessage) => {
      if (session && data.sessionId === session.id) {
        setDemucsInProgress(true);
      }
    };

    const demucsProgressListener = (data: SocketMessage) => {
      if (!demucsInProgressFromBackend && session && data.sessionId === session.id) {
        setDemucsInProgress(true);
      }
    };

    const demucsTerminatedListener = async (data: SocketMessage) => {
      if (session && data.sessionId === session.id) {
        setDemucsInProgress(false);
      }
    };

    emitter.on(SocketMessageType.demucsStarted, demucsStartedListener);
    emitter.on(SocketMessageType.demucsProgress, demucsProgressListener);
    emitter.on(SocketMessageType.demucsTerminated, demucsTerminatedListener);

    return () => {
      emitter.off(SocketMessageType.demucsStarted, demucsStartedListener);
      emitter.off(SocketMessageType.demucsProgress, demucsProgressListener);
      emitter.off(SocketMessageType.demucsTerminated, demucsTerminatedListener);
    };
  }, [session, setDemucsInProgress]);

  const demucsClick = () => {
    const lastSegment: undefined | SegmentModel = getLastSegmentFromSession();

    if (!demucsInProgress && lastSegment && lastSegment.demucs !== null && lastSegment.demucs.id) {
      setOpen(true);
    } else if (demucsInProgress) {
      // send toaster
    } else {
      startDemucs();
    }
  };

  let demucsButtonText: string = 'Extract samples';

  if (demucsInProgress) {
    demucsButtonText = 'Processing...';
  } else if (lastSegmentComp && lastSegmentComp.demucs !== null && lastSegmentComp.demucs.id) {
    demucsButtonText = 'Download samples';
  }

  return (isSmallScreen ? (
    <ActionButtons isSmallScreen={isSmallScreen}>
      <LeftButtons isSmallScreen={isSmallScreen}>
        <ThreeButtons isSmallScreen={isSmallScreen}>
          <Button
            disabled={buttonDisabled}
            type="outlinedGradient"
            size="full"
            onClick={demucsClick}
            text={demucsButtonText}
            leftIcon={demucsInProgress ? <Loader /> : <WavesIcon />}
          />
          <Button
            type="outlinedGradient"
            onClick={() => {
              sendToaster(
                'This feature is not available yet… come back soon!',
                'info',
                setToaster,
              );
            }}
            size="full"
            text="Mix & Masterize"
            leftIcon={<TuneIcon />}
          />
        </ThreeButtons>
        {session?.uploadedAudioS3URL && !prompt ? null : (
          <Button
            type="gradient"
            disabled={buttonDisabled}
            size="full"
            onClick={addResultContainer}
            text="Continue +30sec : 1"
          />
        )}
      </LeftButtons>
      <RightButtons isSmallScreen={isSmallScreen}>
        <Button
          disabled
          type="outlined"
          size="sm"
          text="Duplicate"
          leftIcon={<Duplicate />}
        />
      </RightButtons>
    </ActionButtons>
  ) : (
    <ActionButtons isSmallScreen={isSmallScreen}>
      <LeftButtons isSmallScreen={isSmallScreen}>

        {session?.uploadedAudioS3URL && !prompt ? null : (
          <>
            <Button
              disabled={buttonDisabled}
              type="gradient"
              size="md"
              text={canContinue ? 'Continue +30sec : 1' : 'Generate : 1'}
              rightIcon={<Toll />}
              onClick={() => {
                if (canContinue) {
                  addResultContainer();
                } else {
                  handlePromptWithAudioSubmit(prompt, session);
                }
              }}
            />
            {
              prompt && session?.uploadedAudioS3URL
              && session?.segments?.length === 0
              && (
                <Button
                  type="outlinedGradient"
                  disabled={buttonDisabled}
                  size="md"
                  text="Magic rewrite"
                  onClick={() => handleImproveSubmit(prompt)}
                  rightIcon={
                    isImprovePromptLoading ? <Loader /> : <AutoFixHighIcon />
                  }
                />
              )
            }
          </>
        )}
        <Button
          type="outlinedGradient"
          disabled={buttonDisabled}
          size="md"
          onClick={demucsClick}
          text={demucsButtonText}
          leftIcon={demucsInProgress ? <Loader /> : <WavesIcon />}
        />
        <Button
          type="outlinedGradient"
          size="md"
          onClick={() => {
            sendToaster(
              'This feature is not available yet… come back soon!',
              'info',
              setToaster,
            );
          }}
          text="Mix & Masterize"
          leftIcon={<TuneIcon />}
        />
      </LeftButtons>
      <RightButtons isSmallScreen={isSmallScreen}>
        <Button
          disabled
          type="outlined"
          size="sm"
          text="Duplicate"
          leftIcon={<Duplicate />}
        />
      </RightButtons>
    </ActionButtons>
  ));
};

const ActionButtons = styled.div<{
  isSmallScreen: boolean;
}>(({ isSmallScreen }) => ({
  width: isSmallScreen ? '100%' : 'auto',
  gap: isSmallScreen ? '0.5rem' : '1rem',
  marginTop: isSmallScreen ? 20 : 20,
  display: 'flex',
  flexDirection: isSmallScreen ? 'column' : 'row',
  flex: 1,
}));

const LeftButtons = styled.div<{ isSmallScreen: any }>(({ isSmallScreen }) => ({
  display: 'flex',
  flexDirection: isSmallScreen ? 'column' : 'row',
  gap: '1rem',
}));

const RightButtons = styled.div<{ isSmallScreen?: boolean }>({
  display: 'flex',
  flex: 1,
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 5,
});

const ThreeButtons = styled.div<{ isSmallScreen }>(({ isSmallScreen }) => ({
  width: '100%',
  gap: '1rem',
  justifyContent: 'space-between',
  display: 'flex',
  marginBottom: isSmallScreen ? 5 : 0,
}));
