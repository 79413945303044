import React from 'react';
import { Button as MuiButton, Box } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

type ButtonProps = {
  text?: string;
  onClick?: () => void;
  type?: any;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  disabled?: boolean;
};

export const Button = ({
  text,
  onClick,
  type = 'shadow',
  leftIcon,
  rightIcon,
  disabled,
  size = 'md',
  ...props
}: ButtonProps) => (
  <StyledButton
    disabled={disabled}
    type={type}
    size={size}
    onClick={onClick}
    {...props}
  >
    <Box display="flex" alignItems="center">
      {leftIcon && <Box marginRight={1}>{leftIcon}</Box>}
      {text}
      {rightIcon && <Box marginLeft={1}>{rightIcon}</Box>}
    </Box>
  </StyledButton>
);

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const sizes = {
  sm: { padding: '4px 10px' },
  md: { padding: '8px 16px' },
  lg: { padding: '12px 24px' },
  xl: { padding: '16px 32px' },
  full: { width: '100%' },
};

const StyledButton = styled<any>(MuiButton)(({ type, size }:
  { type: any; size: string; }) => {
  const sizeStyle = sizes[size] || sizes.md;

  switch (type) {
    case 'filtered':
      return {
        ...sizeStyle,
        background: 'rgba(255, 255, 255, 0.1)',
        zIndex: 1,
        borderRadius: '48px',
      };
    case 'shadow':
      return {
        ...sizeStyle,
        background: '#000000',
        boxShadow: '0 4px 80px 7px #B14348',
        zIndex: 1,
        borderRadius: '48px',
      };
    case 'gradient':
      return {
        ...sizeStyle,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        zIndex: 1,
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '-20px',
          top: '-100px',
          width: 'calc(100% + 40px)',
          height: 'calc(100% + 200px)',
          background:
            'linear-gradient(84.44deg, #f73f4d 5.33%, #e3278d 94.63%)',
          zIndex: -1,
          animation:
            size === 'full' ? null : `${rotateAnimation} 3s linear infinite`,
        },
      };
    case 'outlined':
      return {
        ...sizeStyle,
        background: 'transparent',
        zIndex: 1,
        border: '2px solid #4B4B4B',
        borderRadius: '48px',
      };
    case 'outlinedGradient':
      return {
        ...sizeStyle,
        position: 'relative',
        zIndex: 1,
        '&:before': {
          width: '100%',
          height: '100%',
          aspectRatio: '1',
          content: "''",
          position: 'absolute',
          zIndex: '-1',
          inset: '0',
          padding: '2px',
          borderRadius: '50px',
          background:
            'linear-gradient(84.44deg, #f73f4d 5.33%, #e3278d 94.63%)',
          WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        },
      };
    case 'white':
      return {
        ...sizeStyle,
        zIndex: 1,
        background: '#FFFFFF',
        borderRadius: '48px',
      };
    default:
      return {};
  }
});
