import styled from '@emotion/styled';
import { Loader } from './Loader';

interface LoadingProps {
  size?: string;
  message?: string;
}

export const Loading = ({ size = '5em', message = 'loading' }: LoadingProps) => (
  <LoadingContainer>
    <LoadingContent>
      <Loader size={size} />
      <Message>{message}</Message>
    </LoadingContent>
  </LoadingContainer>
);

const Message = styled.div`
  font-size: 1em;
  color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1em;
  color: white;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-content: center;
`;
