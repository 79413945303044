import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size: any }>`
  border-radius: 50%;
  width: ${({ size }) => size || '2.5em'};
  height: ${({ size }) => size || '2.5em'};
  margin: auto;
  font-size: 10px;
  position: relative;
  border-top: 0.3em solid rgba(255, 255, 255, 0.2);
  border-right: 0.3em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
  border-left: 0.3em solid ${({ color }) => color || '#ffffff'};
  transform: translateZ(0);
  animation: ${rotate} 1.2s infinite linear;
`;

interface LoaderProps {
  color?: string;
  size?: string;
}

export const Loader = ({ color = 'white', size = '2.5em' }: LoaderProps) => <Spinner color={color} size={size} />;
