import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { MittProvider } from 'react-mitt';
import '@fontsource/poppins';
import theme from './helpers/MUI/Theme';
import reportWebVitals from './reportWebVitals';
import { digitalPipeConfiguration } from './config';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import { ScrollProvider } from './contexts/ScrollContext';

export const siteId = 3852956;
export const hotjarVersion = 6;

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <div className="bg-black d-block overflow-auto">
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <Auth0Provider
            domain={digitalPipeConfiguration?.domain || ''}
            clientId={digitalPipeConfiguration?.clientId || ''}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: digitalPipeConfiguration?.audience,
              scope: digitalPipeConfiguration?.scope,
            }}
          >
            <HelmetProvider>
              <ScrollProvider>
                <MittProvider />
                <RouterProvider router={router} />
              </ScrollProvider>
            </HelmetProvider>
          </Auth0Provider>
        </RecoilRoot>
      </ThemeProvider>
    </React.StrictMode>
  </div>,
);

reportWebVitals();
