import { useState } from 'react';
import { improvePromptApi } from '@api/improve-prompt.api';

export const useImprovePrompt = (
  getAccessTokenSilently,
  sendToaster,
  setPrompt,
  setToaster,
) => {
  const [isImprovePromptLoading, setIsImprovePromptLoading] = useState(false);

  const handleImproveSubmit = async (prompt) => {
    if (prompt?.trim().length === 0) {
      sendToaster('You cannot send an empty message', 'error', setToaster);
      return;
    }

    try {
      setIsImprovePromptLoading(true);
      const response = await improvePromptApi(
        getAccessTokenSilently,
        prompt?.trim(),
      );
      let improvedPrompt = response.improvedPrompt;

      improvedPrompt = improvedPrompt.replaceAll(/\n+/g, '\n');

      setPrompt(improvedPrompt);
    } catch (e: any) {
      sendToaster(e.message || 'An error occurred', 'error', setToaster);
    } finally {
      setIsImprovePromptLoading(false);
    }
  };

  return {
    isImprovePromptLoading,
    handleImproveSubmit,
  };
};
