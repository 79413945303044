import { UserModel } from '@models';
import { me } from '@api';
import { plainToInstance } from 'class-transformer';
import { SetterOrUpdater } from 'recoil';

export const updateCredits = async (
  getAccessTokenSilently: CallableFunction,
  setUser: SetterOrUpdater<UserModel | null>,
): Promise<UserModel> => new Promise((resolve, reject) => {
  me(getAccessTokenSilently)
    .then((oktaUser: UserModel) => {
      const updatedUser: UserModel = plainToInstance(UserModel, {
        id: oktaUser.id,
        email: oktaUser.email,
        active: oktaUser.active,
        audiocraftModelSettings: oktaUser.audiocraftModelSettings,
        defaultSegmentDuration: oktaUser.defaultSegmentDuration,
        permissions: oktaUser.permissions,
        credits: oktaUser.credits,
      });
      setUser(updatedUser);
      resolve(updatedUser);
    })
    .catch((err) => {
      console.error(err.message);
      reject(err);
    });
});
