import { IconButton, SoundWave } from '@components';
import styled from '@emotion/styled';
import { ReactComponent as PlayIcon } from '@assets/svgs/play.svg';
import { ReactComponent as PauseIcon } from '@assets/svgs/pause.svg';
import { useEffect, useState } from 'react';
import { useResponsive } from '@hooks/useResponsive';

interface SoundPlayerProps {
  url: string;
  title?: string;
  size?: 'sm' | 'lg';
}

export const SoundPlayer = ({ url, title, size = 'lg' }: SoundPlayerProps) => {
  const [wavesurfer, setWavesurfer] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { isMobile } = useResponsive();
  const [currentTime, getCurrentTime] = useState(0);
  const playButtonSize = () => {
    switch (size) {
      case 'sm':
        return 25;
      case 'lg':
        return 45;
      default:
        return 30;
    }
  };

  const onPlayPause = () => {
    wavesurfer?.playPause();
  };

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('audioprocess', () => {
        getCurrentTime(wavesurfer.getCurrentTime());
      });
      wavesurfer.on('interaction', () => {
        getCurrentTime(wavesurfer.getCurrentTime());
      });
    }
  }, [wavesurfer]);

  return (
    <SoundPlayerContainer isMobile={isMobile} size={size}>
      {title ? <Title>{title}</Title> : null}
      <Player isMobile={isMobile} size={size}>
        <PlayButton
          type="gradient"
          onClick={() => {
            onPlayPause();
          }}
          icon={isPlaying ? <Pause size={size} /> : <Play size={size} />}
          size={playButtonSize()}
        />
        <SoundPlayerContent>
          <SoundWave
            size={size}
            url={url}
            setWavesurfer={setWavesurfer}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        </SoundPlayerContent>
      </Player>

      <Timer>
        {wavesurfer
          && `${new Date(currentTime * 1000)
            .toISOString()
            .substr(14, 5)} / ${new Date(wavesurfer.getDuration() * 1000).toISOString().substr(14, 5)}`}
      </Timer>
    </SoundPlayerContainer>
  );
};

const Player = styled.div<{ size: 'sm' | 'lg'; isMobile }>(
  ({ size, isMobile }) => ({
    alignContent: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    gap: size === 'sm' ? 8 : 30,
    marginTop: isMobile ? 20 : 0,
    marginBottom: isMobile ? 20 : 0,
  }),
);

const Pause = styled(PauseIcon)(({ size }: {
  size: 'sm' | 'lg';
}) => ({
  width: size === 'sm' ? 14 : 20,
  height: size === 'sm' ? 14 : 20,
}));

const Play = styled(PlayIcon)(({ size }: {
  size: 'sm' | 'lg';
}) => ({
  width: size === 'sm' ? 14 : 20,
  height: size === 'sm' ? 14 : 20,
}));

const Title = styled.div({
  color: '#fff',
  fontSize: 18,
  fontWeight: 700,
  maxWidth: 200,
  textAlign: 'left',
  lineHeight: 1,
});

const Timer = styled.div({
  color: '#fff',
  fontSize: 18,
  fontWeight: 700,
  textAlign: 'right',
});

const PlayButton = styled(IconButton)({
  color: '#fff',
  fontSize: 50,
});

const SoundPlayerContent = styled.div({
  width: '100%',
  justifyContent: 'center',
});

const SoundPlayerContainer = styled.div<{ size: 'sm' | 'lg'; isMobile }>(
  ({ size, isMobile }) => ({
    display: isMobile && size === 'lg' ? 'block' : 'flex',
    justifyContent: 'space-between',
    alignItems: isMobile && size === 'lg' ? 'flex-start' : 'center',
    flexDirection: isMobile && size === 'lg' ? 'column' : 'row',
    zIndex: 1,
    width: '100%',
    gap: size === 'sm' ? 8 : 30,
  }),
);
