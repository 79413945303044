import { Link } from 'react-router-dom';
import { Button, ProfileMenu } from '@components';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useScroll } from '@contexts';
import { SynthiaLogo } from '@assets/svgs/SynthiaLogo';
import TollIcon from '@mui/icons-material/Toll';
import AddIcon from '@mui/icons-material/Add';
import { useResponsive } from '@hooks/useResponsive';
import { UserModel } from '@models';
import { useRecoilValue } from 'recoil';
import { userModelStore } from '@stores';

interface StyledHeaderProps {
  scrollY: number;
}

const CreditButton = () => {
  const user: UserModel | null = useRecoilValue(userModelStore);
  if (user === null) return null;
  if (user.credits === undefined) return null;

  const creditsText: string = `Credits : ${user?.credits || 0}`;

  return (
    <Button
      size="md"
      leftIcon={<Toll />}
      rightIcon={(
        <AddBadge>
          <Add
            style={{
              height: 15,
              width: 15,
            }}
          />
        </AddBadge>
      )}
      type="filtered"
      text={creditsText}
    />
  );
};

export function Header() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const { scrollY } = useScroll();
  const { isMobile } = useResponsive();

  if (isLoading) return null;
  return (
    <StyledHeader scrollY={scrollY}>
      <Link to="/">
        <Logo />
      </Link>

      <HeaderButtonsContainer>
        {isMobile ? null : <CreditButton />}
        {isAuthenticated && !isLoading ? (
          <ProfileMenu />
        ) : (
          <Button
            type="gradient"
            onClick={() => loginWithRedirect()}
            text="Sign Up"
          />
        )}
      </HeaderButtonsContainer>
    </StyledHeader>
  );
}

const AddBadge = styled.div({
  background: 'linear-gradient(84.44deg, #f73f4d 5.33%, #e3278d 94.63%)',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 2,
});

const Toll = styled(TollIcon)({
  color: '#F73F4D',
  fontSize: 20,
});

const Add = styled(AddIcon)({
  color: 'white',
  fontSize: 14,
});

const HeaderButtonsContainer = styled.div({
  display: 'flex',
  gap: '1rem',
});

const StyledHeader = styled.header<StyledHeaderProps>`
  width: 100%;
  height: 5.5rem;
  z-index: 10000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  background: ${({ scrollY }) =>
    (scrollY > 20
      ? 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
      : 'transparent')};
`;

const Logo = styled(SynthiaLogo)({
  display: 'block',
  width: '300px',
  height: 'auto',
});
