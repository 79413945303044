import { atom, RecoilState } from 'recoil';
import { plainToInstance } from 'class-transformer';

export interface GlobalStoreInterface {
  footerVisible: boolean;
}

export class GlobalStore implements GlobalStoreInterface {
  footerVisible = true;
}

export const globalStore: RecoilState<GlobalStore> = atom<GlobalStore>({
  key: 'globalStore',
  default: plainToInstance(GlobalStore, { footerVisible: true }),
});
