import { plainToInstance } from 'class-transformer';
import { SegmentModel } from '@models';
import { axiosCall } from '@constants';
import { DemucsDto } from '@dto';

export const demucsSegmentApi = async (
  getAccessTokenSilently: CallableFunction,
  segmentId: number,
): Promise<SegmentModel> => axiosCall<SegmentModel>(
  getAccessTokenSilently,
  'demucs',
  'post',
  plainToInstance(DemucsDto, { segmentId }),
);
