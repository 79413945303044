import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { globalStore, GlobalStore } from '@stores';
import { useRecoilValue } from 'recoil';

export const Footer = () => {
  const globalStoreValue: GlobalStore = useRecoilValue(globalStore);
  if (globalStoreValue.footerVisible === false) return null;
  return (
    <FooterContainer>
      <Span>
        © All rights reserved -
        {' '}
        {new Date().getFullYear()}
        {' '}
        -
        {' '}
        <Link to="/">Synthia</Link>
      </Span>
      <Link
        style={{
          zIndex: 30000,
          height: 300,
        }}
        to="/terms"
      >
        Terms & conditions
      </Link>
    </FooterContainer>
  );
};

const FooterContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  color: 'rgba(255, 255, 255, 0.37)',
  fontSize: '0.9rem',
  padding: '1rem',
  width: '100%',
  height: '3rem',
  position: 'fixed',
  bottom: '0',
  zIndex: 2000,
});

const Span = styled.span({});
