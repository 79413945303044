import { useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Prompt,
  PageContainer,
  UploadDropZoneWrapper,
  Loader,
} from '@components';
import { useResponsive } from '@hooks/useResponsive';
import { UploadSampleButton } from '@components/buttons/UploadSampleButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TollIcon from '@mui/icons-material/Toll';
import { useImprovePrompt } from '@hooks/useImprovePrompt';
import { useAuth0 } from '@auth0/auth0-react';
import {
  sendToaster,
  sessionModelStore,
  sessionsModelStore,
  toasterStore,
  userModelStore,
} from '@stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePromptSubmission } from '@hooks/usePromptSubmission';
import { SessionModel, UserModel } from '@models';

interface GenerateButtonsProps {
  promptValue: string;
  handlePromptSubmit: (prompt: string) => void;
  isLoading: boolean;
  isImprovePromptLoading: boolean;
  handleImproveSubmit: (prompt: string) => void;
}

const GenerateButtons = ({
  promptValue,
  handlePromptSubmit,
  isLoading,
  isImprovePromptLoading,
  handleImproveSubmit,
}: GenerateButtonsProps) => (
  <GenerateButtonsContainer>
    <Button
      onClick={() => handlePromptSubmit(promptValue)}
      type="gradient"
      text="Generate : 1"
      rightIcon={isLoading ? <Loader /> : <TollIcon />}
    />
    <Button
      type="outlinedGradient"
      text="Magic rewrite"
      rightIcon={isImprovePromptLoading ? <Loader /> : <AutoFixHighIcon />}
      onClick={() => handleImproveSubmit(promptValue)}
    />
  </GenerateButtonsContainer>
);

export const PromptPage = () => {
  const { isMobile, isTablet, isSmallDesktop } = useResponsive();
  const { getAccessTokenSilently } = useAuth0();
  const [promptValue, setPromptValue] = useState('');
  const user: UserModel | null = useRecoilValue(userModelStore);
  const sessions: SessionModel[] = useRecoilValue(sessionsModelStore);
  const setSessions: SetterOrUpdater<SessionModel[]> = useSetRecoilState(sessionsModelStore);
  const setSession: SetterOrUpdater<SessionModel | null> = useSetRecoilState(sessionModelStore);

  const setToaster = useSetRecoilState(toasterStore);
  const { isImprovePromptLoading, handleImproveSubmit } = useImprovePrompt(
    getAccessTokenSilently,
    sendToaster,
    setPromptValue,
    setToaster,
  );

  const { isLoading, handlePromptSubmit } = usePromptSubmission(
    getAccessTokenSilently,
    user,
    sendToaster,
    setToaster,
    setPromptValue,
    sessions,
    setSessions,
    setSession,
  );

  return (
    <UploadDropZoneWrapper>
      <PageContainer>
        <ContentContainer isMobile={isMobile}>
          <Prompt
            minHeight={
              isMobile ? '20vh' : isTablet || isSmallDesktop ? '20vh' : '30vh'
            }
            value={promptValue}
            setValue={(value: string) => setPromptValue(value)}
            placeholder="Write a prompt to generate music"
          />
          <ActionsContainer>
            {promptValue?.length === 0 ? (
              <UploadSampleButton />
            ) : (
              <GenerateButtons
                promptValue={promptValue}
                handlePromptSubmit={handlePromptSubmit}
                isLoading={isLoading}
                isImprovePromptLoading={isImprovePromptLoading}
                handleImproveSubmit={handleImproveSubmit}
              />
            )}
          </ActionsContainer>
        </ContentContainer>
      </PageContainer>
    </UploadDropZoneWrapper>
  );
};

const ActionsContainer = styled.div({
  width: '100%',
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginTop: '2rem',
});

const ContentContainer = styled.div<{
  isMobile?: boolean;
}>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  paddingLeft: isMobile ? '5vw' : '10%',
  paddingRight: isMobile ? '5vw' : '10%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GenerateButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  width: '100%',
});
