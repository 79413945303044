import { plainToInstance } from 'class-transformer';
import { DigitalPipeConfiguration } from './digital-pipe-configuration.class';

export const prodConfiguration: DigitalPipeConfiguration = plainToInstance(DigitalPipeConfiguration, {
  domain: 'synthia.eu.auth0.com',
  clientId: 'fq6HKbCdKGpGxbV7dw36oxjwXOb6EWC1',
  audience: 'https://api.synthia.co',
  scope: 'openid email profile',
  apiUrl: 'https://api.synthia.co',
  socketUrl: 'https://socket.synthia.co',
});
